import { createAction, props } from "@ngrx/store";
import { SystemUser } from "../../models/systemuser.model";
import { SignInData } from "../../models/sign-in-data.model";
import { RegistrationData } from "../../models/registration-data.model";

export const userRegistration = createAction(
  "[Regitration Page] Register User",
  props<RegistrationData>()
);

export const userRegistrationSuccessful = createAction(
  "[Regitration Page] Register User Successful",
  props<SystemUser>()
);

export const userRegistrationFailed = createAction(
  "[Regitration Page] Register User Failed",
  props<{ error: string }>()
);


export const userInfo = createAction(
  "[User Info] Get User Information"
);

export const userInfoSuccessful = createAction(
  "[User Info] User Information Successful",
  props<SystemUser>()
);

export const userInfoFailed = createAction(
  "[User Info] User Information Failed",
  props<{ error: string }>()
);


export const userSignIn = createAction(
  "[Login Page] Sign-In User",
  props<SignInData>()
);

export const userSignInSuccessful = createAction(
  "[Login Page] Sign-In User Successful"
);

export const userSignInFailed = createAction(
  "[Login Page] Sign-In User Failed",
  props<{ error: string }>()
);


export const userSignOut = createAction(
  "[Login Page] Sign-Out User"
);

export const userSignOutSuccessful = createAction(
  "[Login Page] Sign-Out User Successful"
);

export const userSignOutFailed = createAction(
  "[Login Page] Sign-Out User Failed",
  props<{ error: string }>()
);
