import { createAction, props } from "@ngrx/store";
import { PaymentMethod } from "../../models/payment-method";

export const saveObjectValue = createAction(
  "[Properties Details] Save Object Value Properties",
  props<{ instance: number, amount: number, method: PaymentMethod }>(),
);

export const saveObjectValueSuccessful = createAction(
  "[Properties Details] Save Object Value Successful"
);

export const saveObjectValueFailed = createAction(
  "[Properties Details] Save Object Value Failed",
  props<{ error: string }>()
);
