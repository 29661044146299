<div style="background: #000000; padding: 10px;">
  <div align="right" style="float:right">
    <!--<button type="button" class="btn-close btn-close-white" aria-label="Close"></button>-->
  </div>
  <div style=" text-align: center;">
    <img src="../../assets/nav-menu/PMLogo4.png" alt="Responsive image" style="width: 150px;  padding-right: 10px; padding-top: 0px;" />
  </div>

  <form [formGroup]="investForm" class="form" style="background: #000000; font-family: 'Glacial Indifference'; height: 350px;" autocomplete="off">
    <div style="text-align:center;">
      <div style="font-size:35px;color:white;"><b>{{property?.valueJson?.fullName?? 'Property Name'}}</b></div>
      <div style="font-size:20px;color:white;">Remaining Shares: {{property?.valueJson?.unitsSelling?? property?.valueJson?.remainingShares?? 0}}</div>
      <div style="font-size:20px;color:white;">{{property?.valueJson?.sharePrice?? 0  | currency: 'ZAR': 'symbol-narrow': '1.2-2'}} per share</div>
    </div>

    <div class="form-group">
      <div>
        <input autofocus="" class="form-group__control ng-pristine ng-invalid ng-touched" min="1" max="{{property?.valueJson?.unitsSelling?? property?.valueJson?.remainingShares?? 100}}" style="height: 35px; width: 200px; text-align: center; " formControlName="amount" required type="text" autocomplete="new-amount">
      </div>
      <div class="invalid-feedback" *ngIf="investForm.controls.amount?.errors?.required">
        Please enter an amount
      </div>
      <div class="invalid-feedback" *ngIf="investForm.controls.amount?.errors?.pattern">
        Only accept whole numbers
      </div>
      <div class="invalid-feedback" *ngIf="investForm.controls.amount.errors?.['max']">
        Max of {{property?.valueJson?.unitsSelling?? property?.valueJson?.remainingShares?? 0}} shares stil available
      </div>
      <div class="invalid-feedback" *ngIf="investForm.controls.amount.errors?.['min']">
        Min of 1 share required for purchasing
      </div>
    </div>
    <div style="margin-left: 35%">
      <div style="font-size:15px;color:white;">- {{((investForm?.controls?.amount?.value?? 0) * (property?.valueJson?.sharePrice?? 0)) | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</div>
      <div style="font-size:15px;color:white;">- {{(((investForm?.controls?.amount?.value?? 0) * (property?.valueJson?.sharePrice?? 0)) * (this.config?.value?? 0)) | currency: 'ZAR': 'symbol-narrow': '1.2-2'}} (transaction fee)</div>
      <div style="font-size:15px;color:white;">- {{(((investForm?.controls?.amount.value?? 0) * (property?.valueJson?.sharePrice?? 0)) + (((investForm?.controls?.amount?.value?? 0) * (property?.valueJson?.sharePrice?? 0)) * (this.config?.value?? 0))) | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</div>
    </div>
    <div style="border-bottom: 1px solid; color: white; width: 200px; margin-top: 5px; margin-bottom: 5px; margin-left: 20%"></div>
    <div style="margin-left: 35%">
      <div style="font-size:15px;color:white;">- {{(((investForm?.controls?.amount.value?? 0) * (property?.valueJson?.sharePrice?? 0)) + (((investForm?.controls?.amount?.value?? 0) * (property?.valueJson?.sharePrice?? 0)) * (this.config.value?? 0))) | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</div>
    </div>
    <br />
    <div class="center pb-5 ">
      <button type="button" class="btn btn-primary" [disabled]="!investForm.valid || busy" (click)="submit()"><span>Purchase</span></button>
    </div>
  </form>
</div>
