import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ApiAccessService } from '../../api-access.service';
import { ResetPassword } from '../../models/reset-password.model';
import { userInfo } from '../../state/auth/auth.actions';
import { AuthState } from '../../state/auth/auth.reducer';
import { ResultComponent } from '../result/result.component';



@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  @Input() user: number;
  @Input() from_registration: boolean = false;

  @Output()
  close_model = new EventEmitter<string>();
  resultDialog: NgbModalRef;

  password1: string = "password";
  show1: boolean = false;
  password2: string = "password";
  show2: boolean = false;

  busy: boolean = false;

  changepasswordForm: FormGroup;
  ChangePassword: any;
  constructor(private store: Store<AuthState>, private apiService: ApiAccessService, private modalService: NgbModal, private form_builder: FormBuilder, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.changepasswordForm = this.form_builder.group({
      otp: ['', [Validators.required]],
      newpassword: ['', [Validators.required]],
      confirmpassword: ['', [Validators.required]]
    }, {
      validator: ConfirmedValidator('newpassword', 'confirmpassword')
    });
  }
  reset_password() {
    if (!this.busy && this.changepasswordForm.value) {
      this.busy = true;
      let result: ResetPassword = {
        oTP: this.changepasswordForm.value.otp,
        password: this.changepasswordForm.value.newpassword,
        userId: this.user,
      };
      this.apiService.reset_password(result).subscribe(x => {
        this.busy = false;
        this.resultDialog = this.modalService.open(ResultComponent, { centered: true, modalDialogClass: "modal-rounded" });
        let title = "Password changed!";
        let message = "Your password has been changed successfully.";
        if (this.from_registration) {
          this.store.dispatch(userInfo());
          title = "Registration Complete!";
          message = "Thank you for registering with property magnate. Your account is now active. Please login to start using your new account.";
        }
        this.resultDialog.componentInstance.options = {
          button_message: "Return",
          title: title,
          icon_color: "green",
          icon_string: "fa-solid fa-circle-check",
          message: message
        };
        this.close('Password reset');
      }, fail => {
        this.busy = false;
      });
    }
  }

  close(message: string) {
    this.close_model.emit(message);
    this.activeModal.close(message);
  }

  onClick(value: number) {
    this['password' + value] = !this['show' + value] ? 'text' : 'password';
    this['show' + value] = !this['show' + value];
  }
}


export function ConfirmedValidator(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];
    if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
      return;
    }
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ confirmedValidator: true });
    } else {
      matchingControl.setErrors(null);
    }
  }
}
