<div class="container" style="font-family: 'Glacial Indifference';">
  <h3><b>Transaction History</b></h3>
  <p>You can transfer money between your different account types based on restrictions for the specific accounts.</p>
  <div style="margin: 10px 10px 50px 10px;">
    <table class="table table-container">
      <thead>
        <tr>
          <th class="col">Date</th>
          <th class="col">Description</th>
          <th class="col">Debit</th>
          <th class="col">Credit</th>
          <th class="col">Period</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let d of transactions">
          <td>{{d.transactionDate | date: 'dd/MM/yyyy'}}</td>
          <td>{{d.description}}</td>
          <td>R{{d.debit}}</td>
          <td>R{{d.credit}}</td>
          <td>{{d.period}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
