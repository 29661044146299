import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../dialogs/contact-us/contact-us.component';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  contact_usModal: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  openContact_us() {
    this.contact_usModal = this.modalService.open(ContactUsComponent, { centered: true });
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
