import { CurrencyPipe } from '@angular/common';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxCurrencyModule } from 'ngx-currency';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { environment } from '../environments/environment';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './dialogs/contact-us/contact-us.component';
import { contactUsComponent } from './contact-us/contact-us.component';
import { ChangePasswordComponent } from './dialogs/change-password/change-password.component';
import { MyProfileComponent } from './account/my-profile/my-profile.component';
import { AppComponent } from './app.component';
import { BrowsePropertiesComponent } from './browse-properties/browse-properties.component';
import { PropertyDetailComponent } from './browse-properties/detail/property-detail.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { httpInterceptorProviders } from './http-interceptors';
import { LoginComponent } from './dialogs/login/login.component';
import { ResultComponent } from './dialogs/result/result.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { SuccessfullComponent } from './payment/successfull/successfull.component';
import { ViewComponent } from './properties/view/view.component';
import { RegisterComponent } from './dialogs/register/register.component';
import { AuthEffects } from './state/auth/auth.effects';
import { authReducer } from './state/auth/auth.reducer';
import { ObjectEffects } from './state/object-value/object-value.effects';
import { objectReducer } from './state/object-value/object-value.reducer';
import { PropEffects } from './state/properties/properties.effects';
import { propReducer } from './state/properties/properties.reducer';
import { systemReducer } from './state/system/system.reducer';
import { AlertsComponent } from './system/alerts/alerts.component';
import { FundThisAccountComponent } from './wallet/fund-this-account/fund-this-account.component';
import { InterAccountTransfersComponent } from './wallet/inter-account-transfers/inter-account-transfers.component';
import { WithdrawMoneyComponent } from './wallet/withdraw-money/withdraw-money.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TransactionHistoryComponent } from './wallet/transaction-history/transaction-history.component';
import { ResetPasswordComponent } from './dialogs/reset-password/reset-password.component';
import { InvestComponent } from './dialogs/invest/invest.component';
import { SellSharesComponent } from './dialogs/sell-shares/sell-shares.component';
import { AppService } from './app-service';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    HowItWorksComponent,
    ViewComponent,
    ContactUsComponent,
    AboutUsComponent,
    LoginComponent,
    ResultComponent,
    FooterComponent,
    RegisterComponent,
    AboutUsComponent,
    contactUsComponent,
    LoginComponent,
    BrowsePropertiesComponent,
    DashboardComponent,
    MyProfileComponent,
    ChangePasswordComponent,
    WithdrawMoneyComponent,
    FundThisAccountComponent,
    InterAccountTransfersComponent,
    TransactionHistoryComponent,
    SuccessfullComponent,
    PropertyDetailComponent,
    AlertsComponent,
    PageNotFoundComponent,
    ResetPasswordComponent,
    InvestComponent,
    SellSharesComponent
  ],
  imports: [
    StoreModule.forRoot({ auth: authReducer, properties: propReducer, system: systemReducer, object: objectReducer }),
    EffectsModule.forRoot([AuthEffects, PropEffects, ObjectEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    RouterModule.forRoot([

      { path: '', component: HomeComponent, pathMatch: 'full' },
      { path: 'how-it-works', component: HowItWorksComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'login', component: LoginComponent },
      { path: 'properties/view', component: ViewComponent },
      { path: 'about-us/contact-us', component: ContactUsComponent },
      { path: 'contact-us', component: contactUsComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'browse-properties', component: BrowsePropertiesComponent },
      { path: 'browse-properties/:entity', component: PropertyDetailComponent },
      { path: 'browse-properties/:entity/:type', component: PropertyDetailComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'account/my-profile', component: MyProfileComponent },
      { path: 'wallet/withdraw-money', component: WithdrawMoneyComponent },
      { path: 'wallet/fund-this-account', component: FundThisAccountComponent },
      { path: 'wallet/inter-account-transfers', component: InterAccountTransfersComponent },
      { path: 'wallet/transaction-history', component: TransactionHistoryComponent },
      { path: 'payment/response/:type', component: SuccessfullComponent },
      { path: 'wallet/inter-account-transfers', component: InterAccountTransfersComponent },
      { path: '**', component: PageNotFoundComponent }
    ], { relativeLinkResolution: 'legacy' }),
    SlickCarouselModule,
    NgxCurrencyModule,
    NgbModule
  ],
  providers: [httpInterceptorProviders, CurrencyPipe, AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }
