<div>
  <div class="coont" style="justify-content: center;">
    <h1 class="" style="color: #0f304c; text-align: center; padding-top: 20px; font-family: 'Glacial Indifference';">Fund your Account </h1>
    <div class="card fund-content" >
      <div class="car col-sm-12" style="font-size: 10px; height: 50px; text-align: center; border-bottom: 1px solid silver; padding-top: 5px; padding-bottom: 5px; font-family: 'Glacial Indifference';">
        <div style="color: #0f304c; font-size: 15px;"><b>Fund your account with an EFT deposit</b></div>
        To fund your accounts you can EFT money into that account by using the reference and banking details below.
      </div>
      <div class="fund-innercontent" >
        <div style="display:flex;width:100%">
          <div style="width:40%"> <span style="color:red">•</span> Account Holder:</div>
          <div style="width: 40%;margin-left: 6px; ">Property Magnate</div>
          <div style="width:20%"></div>
        </div>
        <div style="display:flex;width:100%">
          <div style="width:40%;margin-left:6px;">Bank:</div>
          <div style="width:40%">Standard Bank</div>
          <div style="width:20%"></div>
        </div>
        <div style="display:flex;width:100%">
          <div style="width:40%;margin-left:6px;">Account Type:</div>
          <div style="width:40%">Cheque/Current</div>
          <div style="width:20%"></div>
        </div>
        <div style="display:flex;width:100%">
          <div style="width:40%;margin-left:6px;">Branch Code:</div>
          <div style="width:40%">051001</div>
          <div style="width:20%"></div>
        </div>
        <div style="display:flex;width:100%; margin-bottom:10px;">
          <div style="width:40%;margin-left:6px;">Account Number:</div>
          <div style="width:40%">251132542</div>
          <div style="width:20%"><button style="background: white; border: none; border-radius:2px; font-size:15px;" (click)="copy_to_clipboard('251132542')">Copy Details</button></div>
        </div>

        <div style="display:flex;width:100%">
          <div style="width:40%"><span style="color:blue">•</span> Account Holder:</div>
          <div style="width: 40%;margin-left: 6px;">Property Magnate</div>
          <div style="width:20%"></div>
        </div>
        <div style="display:flex;width:100%">
          <div style="width:40%;margin-left:6px;">Bank:</div>
          <div style="width:40%">Standard Bank</div>
          <div style="width:20%"></div>
        </div>
        <div style="display:flex;width:100%">
          <div style="width:40%;margin-left:6px;">Account Type:</div>
          <div style="width:40%">Cheque/Current</div>
          <div style="width:20%"></div>
        </div>
        <div style="display:flex;width:100%">
          <div style="width:40%;margin-left:6px;">Branch Code:</div>
          <div style="width:40%">051001</div>
          <div style="width:20%"></div>
        </div>
        <div style="display:flex;width:100%">
          <div style="width:40%;margin-left:6px;">Account Number:</div>
          <div style="width:40%">251132542</div>
          <div style="width:20%"><button style="background: white; border: none; border-radius: 2px; font-size: 15px;" (click)="copy_to_clipboard('251132542')">Copy Details</button></div>
        </div>
      </div>

      <div class="text-start col-sm-12" style="font-size: 11px; padding: 25px 40px; margin-bottom: 15px; font-family: 'Glacial Indifference';">
        <div class="text-muted pt-2" style="font-size:15px;"><b>Please Note:</b></div>
        <div class="col-lg-12 mx-auto text-start pt-3" style="color: lightslategrey;">
          1. Funds may take up to 48 hours to reflect, due to individual bank regulations.
          <br /> 2. Use of the incorrect reference will result in fund allocation delays, and will require you to email us a formal proof of deposit.
        </div>
      </div>
    </div>

  </div>
</div>
