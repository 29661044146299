import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiAccessService } from '../../api-access.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fund-this-account',
  templateUrl: './fund-this-account.component.html',
  styleUrls: ['./fund-this-account.component.css']
})
export class FundThisAccountComponent implements OnInit {
  transferForm: FormGroup;

  first: boolean = true;
  second: boolean = false;

  constructor(private apiService: ApiAccessService, private formBuilder: FormBuilder, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.transferForm = new FormGroup({
      fromAccount: new FormControl(),
      toAccount: new FormControl(),
      amount: new FormControl(),
      termsCheck: new FormControl(false),
    });
  }

  fund() {
    this.first = true;
    this.second = false;
  
  }

  transfer() {
    this.first = false;
    this.second = true;
  }

  transfer_funds() {
    alert("Under Construction. Requirements needed.");
  }

  cancel() {
    
  }

  copy_to_clipboard(copy: string) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(copy).then(() => {
        alert('Copied to Clipboard')
      }, (error) => {
        console.log(error)
      });
    } else {
      console.log('Browser do not support Clipboard API')
    }
  }

}
