import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ApiAccessService } from "../../api-access.service";
import { saveObjectValue, saveObjectValueFailed, saveObjectValueSuccessful } from "./object-value.actions";

import { of, from } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Router } from "@angular/router";
import { userInfo } from "../auth/auth.actions";


@Injectable()
export class ObjectEffects {
  constructor(private router: Router, private actions$: Actions, private apiService: ApiAccessService) { }
  objectValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveObjectValue),
      switchMap((values) => from(this.apiService.createTransaction(values.instance, values.amount, values.method).pipe(
        map(() => saveObjectValueSuccessful()),
        catchError((error) => of(saveObjectValueFailed({ error })))))
      )
    )
  );

  redirectSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveObjectValueSuccessful),
      tap(() => this.router.navigate(["payment/response/successfull"])),
    )
  }, { dispatch: false }
  );

  getuserinfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveObjectValueSuccessful), 
      map(() => userInfo())
    )
  }
  );

  redirectFailed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveObjectValueFailed),
      tap(() => this.router.navigate(["payment/response/failed"]))
    )
  }, { dispatch: false }
  );
}
