import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiAccessService } from '../../api-access.service';
import { ResultComponent } from '../../dialogs/result/result.component';

@Component({
  selector: 'app-withdraw-money',
  templateUrl: './withdraw-money.component.html',
  styleUrls: ['./withdraw-money.component.css']
})
export class WithdrawMoneyComponent implements OnInit {
  withdrawForm: FormGroup;
  resultDialog: NgbModalRef;

  busy: boolean = false;

  constructor(private apiService: ApiAccessService, private modalService: NgbModal, private router: Router) {

  }

  //balance
  // get list of banks 
  withdrawls = [
    {
      invest: "R0.00",
      locked: "(R0.00)",
      unsettled: "(R0.00)",
      available: "R0.00",
      withdraw: "0.00",
      validation: "Amount must be between R0.01 and R0.00",
    }
  ]
  accounts = [
    { id: 1, Name: 'Property Magnate' },
    {
      id: 2,
      Name: 'Account 2'
    },
    {
      id: 3,
      Name: 'Account 3'
    },
  ]

  ngOnInit(): void {
    this.withdrawForm = new FormGroup({
      amount: new FormControl('', [Validators.required, Validators.min(.01)]),
      fromAccount: new FormControl('', Validators.required),
      toAccount: new FormControl('', Validators.required)
    });
    window.scrollTo(0, 0);
  }

  onSubmit(): void {
    if (this.withdrawForm.invalid) return;
    this.busy = true;
    this.resultDialog = this.modalService.open(ResultComponent, { centered: true, modalDialogClass: "modal-rounded" });
    this.resultDialog.componentInstance.options = {
      button_message: "Return",
      title: "Under Construction!",
      icon_color: "yellow",
      icon_string: "fa-solid fa-circle-exclamation",
      message: "Note withdrawal process is still under construction"
    };
    this.resultDialog.result.then(pass => {
      this.busy = false;
    }, fail => {
      this.busy = false;
    });
  }

  onCancel(): void {
    this.resultDialog = this.modalService.open(ResultComponent, { centered: true, modalDialogClass: "modal-rounded" });
    this.resultDialog.componentInstance.options = {
      button_message: "Return",
      title: "Cancel Withdrawl!",
      icon_color: "yellow",
      icon_string: "fa-solid fa-circle-exclamation",
      message: "Are you sure you want to cancel withdrawl?"
    };
    this.resultDialog.result.then(pass => {
      this.router.navigate(['/']);
    }, fail => {
      this.router.navigate(['/']);
    });
  }
 


}
