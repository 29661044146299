<div #thisModal style="background: #000000; padding: 10px; height:400px;">
  <div align="right" style="float:right">
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="close('closed')"></button>
  </div>
  <div style=" text-align: center; height: 100px; justify-content: center; align-content:center;">
    <img src="../../assets/nav-menu/PMLogo4.png" alt="Responsive image" style="width: 200px;" />
  </div>

  <form [formGroup]="resetpasswordForm" class="form" style=" font-family: 'Glacial Indifference'" autocomplete="off">
    <div style="text-align:center;">
      <div style="font-size:20px;color:white;">Forgot your password?</div>
    </div>

    <div class="form-group" style="margin-top: 30px;">
      <div style="font-family: var(--bs-body-font-family); font-weight: 100; font-size: 13px; color: white; text-align: center;">E-mail address linked to your account</div>
      <div style="width:250px;margin-left:10%">
        <input autofocus="" class="form-group__control ng-pristine ng-invalid ng-touched" style="height: 35px; text-align: center" formControlName="username" required type="text" autocomplete="new-username" placeholder="eg.property@email.co.za">
      </div>
      <div style="height:40px;">
        <div style="margin-left:10%;" class="invalid-feedback" *ngIf="resetpasswordForm.controls.username.errors?.['required']">
          Username is required
        </div>
      </div>
    </div>
    <br />
    <div class="center pb-5">
      <button type="button" style="width: 200px; height: 35px;" class="btn btn-primary" [disabled]="!resetpasswordForm.valid || busy" (click)="req_change_password();"><span style="font-size:17px;">Reset Password</span></button>
    </div>
  </form>
</div>

<ng-template #invalidEmail let-modal>
  <div style="background: #000000; padding: 10px;">
    <div align="right" style="float:right">
      <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div style=" text-align: center; height: 140px;">
      <img src="../../assets/nav-menu/PMLogo4.png" alt="Responsive image" style="width: 200px; padding-right: 10px; padding-top: 0px;" />
    </div>
    <h1 style="color:white;">Email Invalid!</h1>
    <p style="color:white; text-align:center">Please choose a valid email address to change your password.</p>
    <div class="btn-row">
      <button class="btn-withdraw" (click)="modal.dismiss()" type="button">Return</button>
    </div>
  </div>
</ng-template>

