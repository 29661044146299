import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResultDialogModel } from '../../models/result-dialog.model';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.css']
})
export class ResultComponent implements OnInit {
  @Output() close_model = new EventEmitter<string>();
  @Input() public options: ResultDialogModel;

  constructor(private modalService: NgbModal, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {}

  close(message: string) {
    this.close_model.emit(message);
    this.activeModal.close(message);
  }
}
