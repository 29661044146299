import { createReducer, on } from "@ngrx/store";
import { SystemUser } from "../../models/systemuser.model";
import { userRegistration, userRegistrationFailed, userRegistrationSuccessful, userInfo, userInfoFailed, userInfoSuccessful, userSignIn, userSignInFailed, userSignInSuccessful, userSignOut, userSignOutFailed, userSignOutSuccessful } from "./auth.actions";

export interface AuthState {
  user: SystemUser
}

export const initialState: AuthState = {
 user: null
};

export const authReducer = createReducer(
  initialState,
  on(userRegistration, (_) => initialState),
  on(userRegistrationSuccessful, (state, user) => ({ ...state, user: user })),
  on(userRegistrationFailed, (state, _) => initialState),
  on(userInfo, (_) => initialState),
  on(userInfoSuccessful, (state, user) => ({ ...state, user: user })),
  on(userInfoFailed, (state, _) => initialState),
  on(userSignIn, (_) => initialState),
  on(userSignInSuccessful, (state) => ({ ...state })),
  on(userSignInFailed, (state, _) => initialState),
  on(userSignOut, (_) => initialState),
  on(userSignOutSuccessful, (state) => ({ ...state })),
  on(userSignOutFailed, (state, _) => initialState)
);
