import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApiAccessService } from '../api-access.service';
import { Property } from '../models/property.model';
import { AppState } from '../state/app.state';
import { getProperties } from '../state/properties/properties.actions';
import { getProps } from '../state/properties/properties.selectors';

@Component({
  selector: 'app-browse-properties',
  templateUrl: './browse-properties.component.html',
  styleUrls: ['./browse-properties.component.css']
})
export class BrowsePropertiesComponent implements OnInit {

  public show_listing: boolean = true;
  listing: any[] = [];
  selling: any[] = [];
  //properties: Property[] = [];
  //properties$ = this.store.select(getProps).subscribe(x => {
  //  if (x) {
  //    this.properties = x;
  //    this.toggle(true);
  //  }
  //}, err => { });
  ipoSlides_market: any[] = [];
  ipoSlides: any[] = [];

  constructor(private store: Store<AppState>, private apiService: ApiAccessService) { }

  slides = [
    { image: "../../assets/how-it-works/slider-image-1.svg", text: "<p><strong>Register</strong> for an EasyProperties account. It's free and has no minimums.</p>" },
    { image: "../../assets/how-it-works/slider-image-2.svg", text: "<p><strong>Browse</strong> through properties available under new listings and on the market place.</p>" },
    { image: "../../assets/how-it-works/slider-image-3.svg", text: "<p>Either participate in the <strong>latest IPOs or buy shares</strong> available on auction.</p>" },
    { image: "../../assets/how-it-works/slider-image-4.svg", text: "<p><strong>Earn</strong> net rental income through dividends in proportion to how many shares you own.</p>" },
    { image: "../../assets/how-it-works/slider-image-5.svg", text: "<p>Enjoy <strong>capital gains</strong> after the 5-7 year investment period.</p>" },
  ];

  slideConfig = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          deagable: true,
          centerMode: true

        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          deagable: true
        }
      },
      {
        breakpoint: 601,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          deagable: true
        }
      },
      {
        breakpoint: 500,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          deagable: true
        }
      }
    ]
  };

  ipoSlideConfig = { infinite: !0, slidesToShow: 5, slidesToScroll: 5, responsive: [{ breakpoint: 1170, settings: { slidesToShow: 3, slidesToScroll: 3 } }, { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1 } }] };

  ngOnInit() {
    window.scrollTo(0, 0);
    let us = this.apiService.get_properties().subscribe(x => {
      this.listing = x.listing;
      this.selling = x.selling;
      this.is_first_load();
    });
    //const us = this.store.select(getProps).subscribe(x => {
    //  if (!x) {
    //    this.store.dispatch(getProperties());
    //  } else {
    //    us.unsubscribe();
    //  }
    //});

    this.ipoSlides_market = [{
      id: 1,
      image: "../../assets/how-it-works/ipo/blackbrick-ct.png",
      title: "Blackbrick Cape Town",
      raise: "R 9 741 088 ",
      oversubscribed: "168%",
      investors: "7 927",
      totalShares: 250460,
      boughtShares: 100000,
      percentage: 50,
      type: "Coastal Apartments",
      logo: "../../assets/nav-menu/PMLogo4.png"
    },
    {
      id: 1,
      image: "../../assets/how-it-works/ipo/green_park_main.png",
      title: "Greenpark",
      raise: "R  13 246 228",
      oversubscribed: "126%",
      investors: "10 128",
      totalShares: 2000,
      boughtShares: 1000,
      percentage: 60,
      type: "Flat",
      logo: "../../assets/footer/Mag.png"
    },
    {
      id: 1,
      image: "",
      title: "Polofields",
      raise: "R 16 359 585",
      oversubscribed: "119%",
      investors: "13 479",
      totalShares: 1234,
      boughtShares: 123,
      percentage: 70,
      type: "Suburban Apartments",
      logo: "../../assets/about-us/Placeholder3.png"
    }];

    
  }

  is_first_load() {
    var tab = sessionStorage.getItem("tab");
    if (tab == "true" || tab == null) {
      this.toggle(true);
    }
    else {
      this.toggle(false);
    }
}

  toggle(show_listing: boolean) {
    sessionStorage.clear();
    const btn = document.getElementById('true');
    const btn1 = document.getElementById('false');
    if (show_listing) {
      btn.style.backgroundColor = '#CAB000';
      btn.style.color = 'white';
      btn1.style.backgroundColor = 'white';
      btn1.style.color = '#CAB000';
      sessionStorage.setItem("tab", "true");
    }
    else{
      btn1.style.backgroundColor = '#CAB000';
      btn1.style.color = 'white';
      btn.style.backgroundColor = 'white';
      btn.style.color = '#CAB000';
      sessionStorage.setItem("tab", "false");
    }
    
    this.show_listing = show_listing;
    this.ipoSlides = show_listing ? this.listing.map(x => Object.assign({}, x)) : this.selling.map(x => Object.assign({}, x));
  }

  view_property(id: number) {

  }
}
