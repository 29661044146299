
<div class="page-body container">

  <div class="row">
    <div class="header col-sm-12" *ngIf="show">

      <p class="p1"><b>Browse Our Property Selection</b></p>
      <p class="p2">Take your time to browse through tis selection of property investments. Each of our properties have been carefully selected. You can invest as little or as much as you like. Don`t miss your chance to start creating long term wealth through investing in property!</p>
      <div class="head"></div>
    </div>

    <div class="header col-sm-12" *ngIf="hide">

      <p class="p1"><b>See What Others Are Selling</b></p>
      <p class="p2">Take your time to browse through tis selection of property investments. Each of our properties have been carefully selected. You can invest as little or as much as you like. Don`t miss your chance to start creating long term wealth through investing in property!</p>
      <div class="head"></div>
    </div>
  </div>
</div>




<div class="main container">
  <div class="btn1 row">
    
      <button class="btn2 col-sm-6"  (click)="toggle()">New Listings</button>
      <button class="btn2 col-sm-6"  (click)="toggle1()">Marketplace</button>
    
  </div>


  <div class="page-body">
    <div class="child row" id="new-listing" *ngIf="show">

      <div class="col-sm-12">

        <div id="track-report" class="container-fluid container">
          <div class="grid-container row"
               style="width: 700px; height: 1400px;">
            <div *ngFor="let slide of ipoSlides" class="grid-card ">
              <div class="image image-1" [ngStyle]="{ 'background-image' : 'url(' + slide.img + ')'}"></div>
              <div class="load row">
                <div class="load1 col-sm-3">
                  <b>--%{{slide.percentage}}</b><br />
                  Sold
                </div>
                <div class="load2 col-sm-9">
                  <progress value="32" max="100">32%</progress>
                </div>
              </div>
              <div class="text text-center">
                <h2>{{slide.title}}</h2>

                <div class="block">
                  <div class="details">
                    <p class="icons"><i class="fa fa-home"></i>Building name{{slide.name}}</p><br />
                    <p class="icons"><i class="fa fa-location"></i>Location{{slide.location}}</p>
                  </div>

                  <div class="section-1">
                    <p class="title">Avg.Property Valuation</p>
                    <h3 class="figure"><b>{{slide.raise}}</b></h3>


                  </div>
                  <div class="section-2">
                    <p class="title">Avg.Monthly Rental Income</p>
                    <h3 class="figure"><b>{{slide.raise}}</b></h3>

                  </div>




                </div>
              </div>
              <button class="btn">View Properties</button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="child row" id="marketplace" *ngIf="hide">

      <div class="col-sm-12">

        <div id="track-report" class="container-fluid container">
          <div class="grid-container row"
               style="width: 700px; height: 1400px;">
            <div *ngFor="let slide of ipoSlides" class="grid-card ">
              <div class="image image-1" [ngStyle]="{ 'background-image' : 'url(' + slide.img + ')'}"></div>
              <div class="load row">
                <div class="load2 col-sm-12">
                  
                  250,460 Available Shares
                </div>
                
              </div>
              <div class="text text-center">
                <h2>{{slide.title}}</h2>

                <div class="block">
                  <div class="details">
                    <p class="icons"><i class="fa fa-home"></i>Building name{{slide.name}}</p><br />
                    <p class="icons"><i class="fa fa-location"></i>Location{{slide.location}}</p>
                  </div>

                  <div class="section-1">
                    <p class="title">Avg.Property Valuation</p>
                    <h3 class="figure"><b>{{slide.raise}}</b></h3>


                  </div>
                  <div class="section-2">
                    <p class="title">Avg.Monthly Rental Income</p>
                    <h3 class="figure"><b>{{slide.raise}}</b></h3>

                  </div>




                </div>
              </div>
              <button class="btn">View Properties</button>
            </div>

          </div>
        </div>
      </div>
      </div>
    </div>








  </div>

