import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class AppService {
  @Output() display_home_menu: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() { }

  set_display(set: boolean) {
    this.display_home_menu.emit(set);
  }

  show_display() {
    return this.display_home_menu;
  }
}
