import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {

  public show: boolean = true;
  public hide: boolean = false;
  public buttonName: any = 'show';
  constructor() { }

  slides = [
    { img: "../../assets/how-it-works/slider-img-1.svg", text: "<p><strong>Register</strong> for an EasyProperties account. It's free and has no minimums.</p>" },
    { img: "../../assets/how-it-works/slider-img-2.svg", text: "<p><strong>Browse</strong> through properties available under new listings and on the market place.</p>" },
    { img: "../../assets/how-it-works/slider-img-3.svg", text: "<p>Either participate in the <strong>latest IPOs or buy shares</strong> available on auction.</p>" },
    { img: "../../assets/how-it-works/slider-img-4.svg", text: "<p><strong>Earn</strong> net rental income through dividends in proportion to how many shares you own.</p>" },
    { img: "../../assets/how-it-works/slider-img-5.svg", text: "<p>Enjoy <strong>capital gains</strong> after the 5-7 year investment period.</p>" },
  ];


  ipoSlides = [{
    img: "../../assets/how-it-works/ipo/green_reef.jpg",
    title: "Green Reef Village",
    raise: "R  2,794,400",
    oversubscribed: "214%",
    investors: "3 834"
  },
  {
    img: "../../assets/how-it-works/ipo/blackbrick_1.png",
    title: "Blackbrick Sandton",
    raise: "R 3,668,000",
    oversubscribed: "158%",
    investors: "4 374"
  },
  {
    img: "../../assets/how-it-works/ipo/rockefeller.png",
    title: "The Rockefeller",
    raise: "R 5,882,352",
    oversubscribed: "113%",
    investors: "4 200"
  },
  {
    img: "../../assets/how-it-works/ipo/7_on_middle.jpg",
    title: "7 On Middle",
    raise: "R 6 078 788",
    oversubscribed: "204%",
    investors: "7 417"
  },
  {
    img: "../../assets/how-it-works/ipo/blyde.png",
    title: "The Blyde",
    raise: "R 8 088 633",
    oversubscribed: "155%",
    investors: "6 614"
  },
  {
    img: "../../assets/how-it-works/ipo/exchange_lofts_2.jpg",
    title: "The Exchange Lofts",
    raise: "R 9 188 255",
    oversubscribed: "103%",
    investors: "10 770"
  },
  {
    img: "../../assets/how-it-works/ipo/blackbrick-ct.png",
    title: "Blackbrick Cape Town",
    raise: "R 9 741 088 ",
    oversubscribed: "168%",
    investors: "7 927"
  },
  {
    img: "../../assets/how-it-works/ipo/green_park_main.png",
    title: "Greenpark",
    raise: "R  13 246 228",
    oversubscribed: "126%",
    investors: "10 128"
  }];

  slideConfig = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          deagable: true,
          centerMode: true

        }
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true,
          deagable: true
        }
      },
      {
        breakpoint: 601,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          deagable: true
        }
      },
      {
        breakpoint: 500,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          deagable: true
        }
      }
    ]
  };

  ipoSlideConfig = { infinite: !0, slidesToShow: 5, slidesToScroll: 5, responsive: [{ breakpoint: 1170, settings: { slidesToShow: 3, slidesToScroll: 3 } }, { breakpoint: 768, settings: { slidesToShow: 1, slidesToScroll: 1 } }] };

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  toggle() {
    this.hide = false;
    this.show = true;
  }

  toggle1() {
   
    this.hide = true;
    this.show = false;

  }

}

