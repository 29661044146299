 <div class="container">
  <!--<button (click)="ShowHideButton()">
    True
  </button>-->
  <div class="row" *ngIf="trueContent else falseContent">
    <div class="col-md-6 mx-auto mt-5">
      <div class="payment">
        <div class="payment_header">
          <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
        </div>
        <div class="content">
          <h1>Payment SuccessFul!</h1>
          <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. </p>
          <a [routerLinkActive]="['link-active']" [routerLink]="['/dashboard'] ">Go to Dashboard</a>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template class="row" #falseContent>
  <div class="col-md-6 mx-auto mt-5">
    <div class="payment">
      <div class="payment_header">
        <div class="close"><i class="fa fa-close" aria-hidden="true"></i></div>
      </div>
      <div class="content">
        <h1>Payment UnsuccessFul!</h1>
        <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. </p>
        <a [routerLinkActive]="['link-active']" [routerLink]="['/dashboard'] ">Go to Dashboard</a>
      </div>
    </div>
  </div>
</ng-template>
