import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-successfull',
  templateUrl: './successfull.component.html',
  styleUrls: ['./successfull.component.css']
})
export class SuccessfullComponent implements OnInit {
  trueContent: Boolean = true;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.params.forEach(params => {
      if (params.type == "successfull") {
        this.trueContent = true;
      } else {
        this.trueContent = false;
      }
    });
  }

  ShowHideButton() {
    this.trueContent = !this.trueContent;
  }

}
