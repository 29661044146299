import { createReducer, on } from "@ngrx/store";
import { getProperties, getPropertiesFailed, getPropertiesSuccessful } from "./properties.actions";

export interface PropertiesState {
  properties: any[]
}

export const initialState: PropertiesState = {
  properties: null
};

export const propReducer = createReducer(
  initialState,
  on(getProperties, (_) => initialState),
  on(getPropertiesSuccessful, (state, p) => ({ ...state, properties: [...p.properties] })),
  on(getPropertiesFailed, (state, _) => initialState)
);
