import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ApiAccessService } from '../../api-access.service';
import { _ObjectValue } from '../../models/object-value.model';
import { PaymentMethod } from '../../models/payment-method';
import { Property } from '../../models/property.model';
import { SystemUser } from '../../models/systemuser.model';
import { UserInfo } from '../../models/user-info';
import { AppState } from '../../state/app.state';
import { getUser } from '../../state/auth/auth.selectors';
import { saveObjectValue } from '../../state/object-value/object-value.actions';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { RequirementDetail } from '../../models/requirement-detail.model';
import { LoginComponent } from '../../dialogs/login/login.component';
import { InvestComponent } from '../../dialogs/invest/invest.component';

//import { Options, LabelType } from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-view-properties',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.css']
})
export class PropertyDetailComponent implements OnInit {
  systemUser$ = this.store.select(getUser);
  systemuser: SystemUser;
  paymentForm: FormGroup;
  investModal: NgbModalRef;
  loginModal: NgbModalRef;

  selected_child: _ObjectValue;
  value: number = 60;
  is_for_market: boolean = false;

  property$: Observable<UserInfo>;
  property: UserInfo;

  constructor(private store: Store<AppState>, private currencyPipe: CurrencyPipe, private route: ActivatedRoute, private apiService: ApiAccessService, private modalService: NgbModal) { }

  openInvest() {
    this.investModal = this.modalService.open(InvestComponent, { centered: true });
    this.investModal.componentInstance.property = this.selected_child;
    this.investModal.result.then(pass => {
      this.get_property_info(this.property.entity.id);
    }, fail => { });
  }

  openLogin() {
    this.loginModal = this.modalService.open(LoginComponent, { centered: true});
  }

  get_property_info(entity?: number) {
    if (entity && entity > 0) {
      let p = this.apiService.get_property(entity, this.is_for_market)
        .subscribe(x => {
          if (x) {
            this.property = x;
            if (x.entityObject?.children[0]?.data?.length == 1) this.selected_child = x.entityObject.children[0].data[0];
            if (this.selected_child && x.entityObject?.children[0]?.data?.length) {
              var selected = x.entityObject?.children[0]?.data?.filter(x => x.id == this.selected_child.id);
              if (selected?.length > 0) {
                this.selected_child = selected[0];
              }
            }
            p.unsubscribe();
          }
        }, e => { });
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.is_for_market = this.route.snapshot.params.type ? true : false;
    this.systemUser$.subscribe(user => { this.systemuser = user; })
    if (history.state.entity) {
      this.get_property_info(history.state.entity);
    } else {
      this.get_property_info(+this.route.snapshot.params.entity);
    }
    this.paymentForm = new FormGroup({
      amount: new FormControl('', [Validators.required, Validators.min(100)]),
      method: new FormControl('', [Validators.required])
    });
  }

  progress(balance, target) {
    if (balance == 0 || target == 0) return 0;
    return balance / target;
  }

  setProgressStyle(progress) {
    var progressStyle: Record<string, any> = {};

    progressStyle = {
      'background-color': Math.floor(progress * 100) / 100 < 1 ? '#005bff' : '#31ff00',
      'width': Math.floor(progress) < 1 ? Math.floor(progress * 100) + '%' : '100%'
    };
    return progressStyle;
  }

  select_property(child: _ObjectValue) {
    this.selected_child = child;
  }

  download_doc(doc: RequirementDetail) {
    if (doc.attachments?.length > 0) {
      if (doc.attachments?.length == 1) {
        this.apiService.get_file("attachment/file/" + doc.attachments[0].id);
      } else {
        this.apiService.get_file("attachment/zip/requirement/" + doc.id);
      }
    }
  }
}

