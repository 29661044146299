import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inter-account-transfers',
  templateUrl: './inter-account-transfers.component.html',
  styleUrls: ['./inter-account-transfers.component.css']
})
export class InterAccountTransfersComponent implements OnInit {

  first: boolean = false;
  second: boolean = true;


  constructor() { }

  ngOnInit(): void {
  }

  fund() {
    this.first = true;
    this.second = false;

  }

  transfer() {
    this.first = false;
    this.second = true;
  }

}
