import { Component, OnInit } from '@angular/core';
import { getUser } from '../state/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app.state';


@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  systemUser$ = this.store.select(getUser);

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
  
  }

}
