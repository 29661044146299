

export class Alert {
  constructor(message: string, type: number) {
    this.message = message;
    this.typeId = type;
    switch (type) {
      case 0:
        this.typeName = "success";
        break;
      case 1:
        this.typeName = "warning";
        break;
      case 2:
        this.typeName = "danger";
        break;
      case 3:
        this.typeName = "info";
        break;
      default:
        throw new Error("Unknown Alert Type");
    }
  }
  message: string;
  typeId: number;
  typeName: "success" | "danger" | "warning" | "info";

}
