import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiAccessService } from '../../api-access.service';
import { _ObjectValue } from '../../models/object-value.model';
import { ShareSelling } from '../../models/share-selling.model';

@Component({
  selector: 'app-sell-shares',
  templateUrl: './sell-shares.component.html',
  styleUrls: ['./sell-shares.component.css']
})
export class SellSharesComponent implements OnInit {
  @Input() property: any;
  @Output() close_model = new EventEmitter<string>();

  sellsharesForm: FormGroup;
  busy: boolean = false;

  constructor(private apiService: ApiAccessService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.sellsharesForm = new FormGroup({
      amount: new FormControl('', [Validators.required, Validators.max(this.property?.units?? 0)]),
    });
  }

  close(message?: string) {
    this.close_model.emit(message);
    this.activeModal.close(message);
  }

  submit() {
    if (this.sellsharesForm.invalid || this.sellsharesForm?.value?.amount == 0) return;
    this.busy = true;

    let s: ShareSelling = {
      unitPrice: this.property.currentSharePrice,
      property: this.property.property,
      units: this.sellsharesForm.value.amount
    };

    this.apiService.sell_share(s).subscribe(x => {
      this.busy = false;
      this.close("sucessful");
    }, error => {
      this.busy = false;
    });
  }
}
