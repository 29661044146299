<div class="content" style="background: #000000; font-family: 'Glacial Indifference';">
  <form [formGroup]="loginForm" (ngSubmit)="submit_loginform(loginForm.value)" class="form" style="background: #000000;" autocomplete="off">
    <div style="margin-left:50px;">
      <img src="../../assets/nav-menu/PMLogo4.png" alt="Responsive image" style="width: 150px; height:57px;padding-right: 10px; padding-top: 0px;" (click)="close('closed')" />
    </div>

    <div class="top-header-cont">
      <div class="top-header-title">Log in to your account</div>
      <div class="top-header-sub">Welcome back!</div>
    </div>

    <div class="form-group">
      <label class="required">Name</label>
      <input autofocus="" class="form-group__control ng-pristine ng-invalid ng-touched" formControlName="username" required type="text" autocomplete="new-username">
      <div *ngIf="loginForm.controls.username.invalid && (loginForm.controls.username.dirty || loginForm.controls.username.touched)">
        <div class="invalid-feedback" *ngIf="loginForm.controls.username.errors?.['required']">Username is required</div>
      </div>
    </div>

    <div class="form-group">
      <label class="required">Password</label>
      <input autofocus="" class="form-group__control ng-pristine ng-invalid ng-touched" formControlName="password" required type="password" autocomplete="new-password">
      <div *ngIf="loginForm.controls.password.invalid && (loginForm.controls.password.dirty || loginForm.controls.password.touched)">
        <div class="invalid-feedback" *ngIf="loginForm.controls.password.errors?.['required']">Password is required</div>
      </div>
    </div>


    <div class="checkbox">
      <input type="checkbox" />
      <label> Remember Me</label>
      <a class="forgot-password"  (click)="openResetPassword();">
        <u> Forgot password?</u>
      </a>
    </div>

    <div class="actions">
      <div class="form-actions">
        <button class="button button-sign-in button--dark-blue" type="submit" value="Login" [disabled]="!loginForm.valid">
          Sign in
        </button>
      </div>
      <div class="form-actions">
        <button class="button button-google button--dark-blue" type="submit" value="Login" [disabled]="!loginForm.valid">
          <i class="fab fa-google" style="color: #e9da62; "></i> Sign in with Google
        </button>
      </div>
    </div>
    <div class="bottom center">
      <p class="learn-more">
        Don't have an account?
        <a style="cursor:pointer" style="color: #e9da62; " class="learn-more" (click)="openRegister();">
          Sign up
        </a>
      </p>
    </div>
  </form>
</div>
