
<footer class="page-footer">
  <div class="footer-4"></div>
  <div class="footer-1 row">

    <div class="footer-logo col-6">
      <a>
        <img src="../../assets/footer/Mag.png" alt="Responsive image" style="width: 150px;"/>

      </a>
    </div>

    <div class="footer-socials row col-6">
      <ul>
        <li><a href="#"><i class="fab fa-instagram"></i></a></li>
        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
        <li><a href="#"><img src="../../assets/footer/tiktok-icon.svg" alt="Responsive image" /></a></li>
      </ul>
    </div>
  </div>

  <div class="footer-4"></div>

  <div class="footer-2 row">
    <div class="footer-info col-6">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad  Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
    </div>
    <div class="footer-menu1 row col-6">
      <div class="footer-menu col-sm-6">
        <ul class="press">
          <li><a href="../../assets/footer/terms-and-conditions.docx" target="_blank"><span>terms and conditions</span></a></li>
          <li><a href="../../assets/footer/website-terms-of-use.docx"><span>TERMS OF USE</span></a></li>
          <li><a href="../../assets/footer/access-to-information.docx"><span>access to information</span></a></li>
          <li><a href="../../assets/footer/privacy-policy.docx"><span>PRIVACY POLICY</span></a></li>
        </ul>
      </div>
      <div class="footer-menu col-sm-6 ">
        <ul class="press">
          <li><a href="../../assets/footer/fais-dislosure.docx"><span>FAIS DISCLOSURE</span></a></li>
          <li><a href="../../assets/footer/cost-profile.docx"><span>COST PROFILE</span></a></li>
          <li><a href="../../assets/footer/complaints-policy.docx"><span>COMPLAINTS PROCESS</span></a></li>
        </ul>
      </div>
    </div>  
    <!--<div class="footer-menu1 row col-6">
      <div class="footer-menu col col-sm-6">
        <ul>
          <li><a href="../../assets/footer/terms-and-conditions.docx" target="_blank"><p>terms and conditions</p></a></li>
          <li><a href="../../assets/footer/website-terms-of-use.docx"><p>TERMS OF USE</p></a></li>
          <li><a href="../../assets/footer/access-to-information.docx"><p>access to information</p></a></li>
          <li><a href="../../assets/footer/privacy-policy.docx"><p>PRIVACY POLICY</p></a></li>
        </ul>
      </div>
      <div class="footer-menu col col-sm-6 order-first order-sm-last">
        <ul>
          <li><a href="../../assets/footer/fais-dislosure.docx"><p>FAIS DISCLOSURE</p></a></li>
          <li><a href="../../assets/footer/cost-profile.docx"><p>COST PROFILE</p></a></li>
          <li><a href="../../assets/footer/complaints-policy.docx"><p>COMPLAINTS PROCESS</p></a></li>
        </ul>
      </div>
    </div>-->
  </div>

  <div class="footer-copyright row">
    <p>&copy;2022 Property Magnate, Cape Town, South Africa. All Rights Reserved. </p>
  </div>
</footer>

