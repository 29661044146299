import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';
import { ApiAccessService } from '../api-access.service';
import { ResultComponent } from '../dialogs/result/result.component';
import { SellSharesComponent } from '../dialogs/sell-shares/sell-shares.component';
import { Property } from '../models/property.model';
import { ShareSelling } from '../models/share-selling.model';
import { Transaction } from '../models/transaction.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private apiService: ApiAccessService, private modalService: NgbModal) { }

  sellsharesModal: NgbModalRef;
  resultDialog: NgbModalRef;

  toggled: string;
  toggled_market: string;
  property: any;

  properties: any[] = [];
  selling: any[] = [];

  progress(balance, target) {
    return balance / target;
  }

  setProgressStyle(progress) {
    var progressStyle: Record<string, any> = {};

    progressStyle = {
      'background-color': Math.floor(progress * 100) / 100 < 1 ? '#005bff' : '#31ff00',
      'width': Math.floor(progress) < 1 ? Math.floor(progress * 100) + '%' : '100%'
    };

    return progressStyle;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.get_dashboard();
  }

  get_dashboard() {
    this.apiService.get_dashboard().subscribe(x => {
      this.properties = x.bought;
      this.selling = x.selling;
    });
  }

  sum(value: string, parent?: string) {
    let total = 0;
    if (this.properties?.length) {
      for (var i = 0; i < this.properties.length; i++) {
        if (this.properties[i][value]) {
          if (!parent || this.properties[i].parentName == parent) {
            total += this.properties[i][value];
          }
        }
      }
    }
    return total;
  }

  toggleTile(p) {
    if (this.toggled == p) {
      this.toggled = null;
    } else {
      this.toggled = p;
    }
  }

  distribution(parent: string) {
    let total = this.sum('units');
    let property = this.sum('units', parent);
    return (property / total);
  }

  sell_shares(property) {
    this.sellsharesModal = this.modalService.open(SellSharesComponent, { centered: true });
    this.sellsharesModal.componentInstance.property = property;
    this.sellsharesModal.result.then(pass => {
      this.get_dashboard();
    }, fail => { });
  }

  sum_market(value: string, parent?: string) {
    let total = 0;
    if (this.selling?.length) {
      for (var i = 0; i < this.selling.length; i++) {
        if (this.selling[i][value]) {
          if (!parent || this.selling[i].parentName == parent) {
            total += this.selling[i][value];
          }
        }
      }
    }
    return total;
  }

  toggle_market(p) {
    if (this.toggled_market == p) {
      this.toggled_market = null;
    } else {
      this.toggled_market = p;
    }
  }

  remove_shares(property) {
    this.resultDialog = this.modalService.open(ResultComponent, { centered: true, modalDialogClass: "modal-rounded" });
    this.resultDialog.componentInstance.options = {
      button_message: "Continue",
      title: "Remove these shares from Market?",
      icon_color: "green",
      icon_string: "fa-solid fa-circle-check",
      message: "Are you sure you want to take these shares off the market?"
    };
    this.resultDialog.result.then(pass => {
      let reverting: ShareSelling = {
        property: property.property,
        units: 0
      };
      this.apiService.revert_selling_share(reverting).subscribe(x => {
        this.get_dashboard();
      });
    }, fail => { });

  }
}
