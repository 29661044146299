<div class="container">
  <div class="row">
    <div class="col-sm-12 text-center pt-5"> <h2 class="display-6" style="color: #0f304c;">Fund your Account</h2></div>
  </div>

  <div class="row">
    <div class="col-sm-12 btn-group btn-group-lg" role="group" aria-label="Basic example">
      <button type="button" class="btn btn-info text-muted" (click)="fund()">Make an EFT Deposit</button>
      <button type="button" class="btn btn-info  text-muted" (click)="transfer()">Inter-account Transfer</button>
    </div>
  </div>
</div>

<div class="page-body">
  <!---------------First Tab-------------------->

  <div class="card pt-3" *ngIf="first">
    <div class="row">
      <div class="card-header text-center col-sm-12">
        <h4 style="color: #0f304c;">Fund your account with an EFT deposit</h4>
        <div class="display-7 mx-auto px-0 pt-2 text-center ">
          To fund your accounts you can EFT money into that account by using the reference and banking details below.
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="section-body">
        <div class="row p-2 pb-2">
          <div class="col-sm text-muted">
            YOUR ACCOUNTS
          </div>
        </div>
        <div class="row p-2 pb-2">
          <div class="col-sm mx-auto text-muted">
            EFT REFERENCE
          </div>
        </div>
        <div class="row  align-bottom">
          <div class="col-sm ">
            <ul>
              <li class="bullet1">EasyEquities ZAR</li>
            </ul>
          </div>
        </div>
        <div class="row align-bottom">
          <div class="col-sm mx-auto">
            <p style="color: #0f304c">EE-7745801</p>
          </div>
        </div>
        <div class="row align-bottom">
          <div class="col-sm mx-auto">
            <button type="button" class="btn btn-copy">Copy</button>
          </div>
        </div>
        <div class="row align-bottom">
          <div class="col-sm">
            <ul>
              <li class="bullet">EasyProperties ZAR</li>
            </ul>
          </div>
        </div>
        <div class="row align-bottom">
          <div class="col-sm mx-auto">
            <p style="color: #0f304c">EE-7745805</p>
          </div>
        </div>
        <div class="row align-bottom">
          <div class="col-sm mx-auto ">
            <button type="button" class="btn btn-copy">Copy</button>
          </div>
        </div>
      </div>

      <div class="text-start p-2 pt-4">
        <div style="color: #0f304c;">Select one of our accounts:</div>
        <div class="display-7 col-lg-12 mx-auto px-0 text-start" style="color: lightslategrey;">
          For quick fund allocation, please transfer into the same bank as the one you are with.
        </div>
      </div>

      <form>
        <div class="row p-2 pt-4">
          <div class="form-group col-sm">
            <select class="col-sm form-select form-select-s mb-3" aria-label=".form-select-s example">
              <option selected>
                Select a bank
              </option>
              <option value="1">Absa</option>
              <option value="2">FnB</option>
              <option value="3">Nedbank</option>
            </select>
          </div>
        </div>
      </form>

      <div class="text-start p-2 pt-4">
        <div class="text-muted pt-2">PLEASE NOTE:</div>
        <div class="col-lg-12 mx-auto text-start pt-3" style="color: lightslategrey;">
          <ol>
            <li>Funds may take up to 48 hours to reflect, due to individual bank regulations.</li>
            <li>Use of the incorrect reference will result in fund allocation delays, and will require you to email us a formal proof of deposit.</li>
          </ol>
        </div>
      </div>

      <div class="d-flex justify-content-center pt-5 pb-3">
        <button type="button" class="btn btn-primary btn-lg">Done</button>
      </div>

    </div>
  </div>

  <!---------------Second Tab-------------------->

  <div class="card pt-3" *ngIf="second">
    <div class="row">
      <div class="card-header text-center col-sm-12">
        <h4 style="color: #0f304c;">Inter-account Transfers</h4>
        <div class="display-7 col-sm-12 mx-auto px-0 pt-2 text-center ">
          You can transfer money between your different account types based on restrictions for the specific accounts.
        </div>
      </div>
    </div>
    <div class="card-body">

      <div class="section-body">
        <div class="row pt-2">
          <div class="col-sm">
            <h5>From Account</h5>
          </div>
          <div class="col-sm mx-auto text-muted">
            <div class="col-sm">
              <div class="form-group col-sm-12">
                <select class="form-select  mb-3" aria-label=".form-select-lg example">
                  <option selected value="1">EasyEquities ZAR</option>
                  <option value="2">EasyProperties ZAR</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm">
            Available Funds
          </div>

          <div class="col-sm mx-auto">
            R 0.00
          </div>
        </div>
        <div class="row">
          <div class="col-sm text-muted">
            Amount Remaining
          </div>

          <div class="col-sm mx-auto text-muted">
            R 0.00
          </div>
        </div>
      </div>

      <div class="section-2">
        <div class="row">
          <div class="col-sm display-7 text-muted">
            TRANSFER FUNDS TO
          </div>
          <div class="col-sm mx-auto text-muted">

          </div>
          <div class="col-sm mx-auto text-muted">
            AMOUNT TO TRANSFER
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-sm display-7">
            <div class="form-group col-sm-12">
              <select class="form-select  mb-3" aria-label=".form-select-lg example">
                <option selected value="1">EasyEquities ZAR</option>
                <option value="2">EasyProperties ZAR</option>
              </select>
            </div>
          </div>
          <div class="col-sm mx-auto text-muted">
            <form>
              <div class="form-group row col-sm-12">
                <label for="inputPassword" class="col-sm-2 col-form-label">R</label>
                <div class="col-sm-10">
                  <input type="number" class="form-control" min="0" id="inputPassword" placeholder="0.00">
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="text-start p-2 pt-5">
        <div class="text-muted pt-2">PLEASE NOTE:</div>
        <div class="col-lg-12 mx-auto text-start pt-3" style="color: lightslategrey;">
          There will be a 40-day lockup period on your transferred funds once they have been processed. You will not be able to withdraw your funds until the lockup period has passed.
        </div>
      </div>

      <div class="d-flex justify-content-center pb-3 pt-5">
        <span style="padding-right:10px"><button type="button" class="btn btn-info btn-lg">Cancel</button> </span>
        <span><button type="button" class="btn btn-primary btn-lg">Transfer Funds</button></span>
      </div>

    </div>
  </div>

</div>

