import { createAction, props } from "@ngrx/store";

export const getProperties = createAction(
  "[Browse Properties] Get All Properties"
);

export const getPropertiesSuccessful = createAction(
  "[Browse Properties] Get All Properties Successful",
  props<{ properties: any[] }>()
);

export const getPropertiesFailed = createAction(
  "[Browse Properties] Get All Properties Failed",
  props<{ error: string }>()
);
