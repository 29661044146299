<div class="content" style="background: #000000; font-family: 'Glacial Indifference';">
  <form [formGroup]="registerForm" (ngSubmit)="submit_registerform(registerForm.value)" autocomplete="off">
    <div style="margin-left:50px;">
      <img src="../../assets/nav-menu/PMLogo4.png" alt="Responsive image" style="width: 150px; padding-right: 10px; padding-top: 0px;" />
    </div>

    <div style="text-align: center; margin-bottom: 10px;">
      <div style="font-size:25px;color:white;">Register an account</div>
      <div style="font-size: 15px;color: white;">Start your investment journey!</div>
    </div>

    <div class="form-group">
      <h6>Username</h6>
      <input autofocus="" class="form-group__control ng-pristine ng-invalid ng-touched" style="height: 35px;" formControlName="username" required type="email" autocomplete="new-username">
      <div *ngIf="registerForm.controls.username.invalid && (registerForm.controls.username.dirty || registerForm.controls.username.touched)">
        <div class="invalid-feedback" *ngIf="registerForm.controls.username.errors?.['required']">Username is required</div>
      </div>
    </div>
    <div class="form-group">
      <h6>Email</h6>
      <input autofocus="" class="form-group__control ng-pristine ng-invalid ng-touched" style="height: 35px;" formControlName="email" required type="text" autocomplete="new-email">
      <div *ngIf="registerForm.controls.email.invalid && (registerForm.controls.email.dirty || registerForm.controls.email.touched) ">
        <div class="invalid-feedback" *ngIf="registerForm.controls.email.errors?.['required']">
          Email is required
        </div>
        <div class="invalid-feedback" *ngIf="registerForm.controls.email.errors?.['pattern']">
          Enter a valid Email eg(example@gmail.com)
        </div>
      </div>
    </div>
    <!--<div class="form-group">
      <h6>Password</h6>
      <input autofocus="" class="form-group__control ng-pristine ng-invalid ng-touched" style="height: 35px;" formControlName="password1" required type="password" pattern="^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$" autocomplete="new-password1">
      <div class="invalid-feedback" *ngIf="registerForm.controls.password1?.invalid && (registerForm.controls.password1?.dirty || registerForm.controls.password1?.touched)">
        <div class="invalid-feedback" *ngIf="registerForm.controls.password1.errors?.['required']">Password is required</div>
        <div *ngIf="registerForm.controls.password1?.errors?.pattern">
          Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
          letter and 1 number.
        </div>
      </div>
    </div>-->

    <div class="actions" style="padding-top: 2px;">
      <div class="form-actions">
        <button class="button button--dark-blue" type="submit" value="Register" [disabled]="!registerForm.valid || busy" style="background: #e9da62; color: black; font-family: 'Glacial Indifference';">
          {{busy? 'Busy Processing please wait...' : 'Get Started'}}
        </button>
      </div>
      <div class="form-actions">
        <button class="button button--dark-blue" type="submit" value="Register" [disabled]="!registerForm.valid || busy" style="background: black; color: white; border-width: thin; border-style: ridge; border-color: #e9da62; font-family: 'Glacial Indifference';">
          <i class="fab fa-google" style="color: #e9da62; "></i> Sign up with Google
        </button>
      </div>
    </div>
    <div class="bottom" style="text-align: center;">
      <p class="learn-more" style="color: #ffffff; font-size: 12px; justify-content: center;">
        Already have an account?
        <a style="cursor:pointer" class="learn-more" (click)="openLogin();">
          Log in
        </a>
      </p>
    </div>
  </form>
</div>

