<div style="background: #000000; padding: 10px;">
  <div align="right" style="float:right">
    <button type="button" class="btn-close btn-close-white" (click)="close()" aria-label="Close"></button>
  </div>
  <div style=" text-align: center; height: 100px; justify-content: center; align-content:center;">
    <img src="../../assets/nav-menu/PMLogo4.png" alt="Responsive image" style="width: 200px;" />
  </div>

  <form [formGroup]="sellsharesForm" class="form" style="background: #000000; font-family: 'Glacial Indifference'; height: 350px;" autocomplete="off">
    <div style="text-align:center;">
      <div style="font-size:35px;color:white;"><b>{{property?.propertyName?? 'Property Name'}}</b></div>
      <div style="font-size:20px;color:white;">Remaining Shares: {{property?.units?? 0}}</div>
      <div style="font-size:20px;color:white;">{{property?.currentSharePrice?? 0  | currency: 'ZAR': 'symbol-narrow': '1.2-2'}} per share</div>
    </div>

    <div class="form-group">
      <div>
        <input autofocus="" class="form-group__control ng-pristine ng-invalid ng-touched" max="{{property?.units?? 0}}" style="height: 35px; width: 200px; text-align: center; " formControlName="amount" required type="text" autocomplete="new-amount">
      </div>
      <div class="invalid-feedback" *ngIf="sellsharesForm.controls.amount.errors?.['required']">
        Please enter an amount
      </div>
      <div class="invalid-feedback" *ngIf="sellsharesForm.controls.amount.errors?.['max']">
        Max of {{property?.units?? 0}} shares stil available
      </div>
    </div>
    <div style="margin-left: 35%">
      <div style="font-size:15px;color:white;">+{{(((sellsharesForm?.controls?.amount.value?? 0) * (property?.currentSharePrice?? 0))) | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</div>
    </div>
    <br />
    <div class="center pb-5 ">
      <button type="button" class="btn btn-primary" [disabled]="!sellsharesForm.valid || busy" (click)="submit()"><span>Sell Shares</span></button>
    </div>
  </form>
</div>
