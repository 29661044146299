<div class="main-container">
 

  <form [formGroup]="Contact_usForm" class="form" (submit)="submit_form()" style="background: #000000; font-family: 'Glacial Indifference'; height: 100%" autocomplete="off">
    <div style=" text-align: center; height: 50px; justify-content: center; align-content:center;">
      <img src="../../assets/nav-menu/PMLogo4.png" alt="Responsive image" style="width: 150px;" />
    </div>
    <div style="text-align:center;padding: 10px 0 5px;letter-spacing:1px">
      <div style="font-size:22px;color:white; ">Contact Us</div>
      <div style="font-size: 9px;color: white;font-weight:100">Got a question? We'd love to hear from you. <br /> and we'll respond as soon as possible.</div>
    </div>

    <div class="form-group">
      <h6 style="font-size: 10px;color: white;">Name</h6>
      <input autofocus="" class="form-group__control ng-pristine ng-invalid ng-touched" formControlName="username" style="height: 30px; width: 95%;border-radius:0 " required type="text">
      <div class="invalid-feedback" *ngIf="Contact_usForm.controls.username.errors?.['required']">Username is required</div>
    </div>
    <div class="form-group">
      <h6 style="font-size: 10px;color: white;">Email address</h6>
      <input autofocus="" class="form-group__control ng-pristine ng-invalid ng-touched" formControlName="email" style="height: 30px; width: 95%; border-radius: 0" required type="text">
      <div class="invalid-feedback" *ngIf="Contact_usForm.controls.email.errors?.['required']">
        Email is required
      </div>
      <div class="invalid-feedback" *ngIf="Contact_usForm.controls.email?.errors?.pattern">
        Enter a valid Email eg(example@gmail.com)
      </div>
    </div>
    <div class="form-group">
      <h6 style="font-size: 10px;color: white;">Message</h6>
      <input autofocus="" class="form-group__control ng-pristine ng-invalid ng-touched" formControlName="message" required type="text" style="height: 80px; width: 95%; border-radius: 0">
      <div class="invalid-feedback" *ngIf="Contact_usForm.controls.message.errors?.['required']">
        Message is required
      </div>
    </div>

    <div class="center pb-5 ">
      <button type="submit" class="btn btn-primary center" [disabled]="!Contact_usForm.valid || busy">Submit</button>
    </div>

  </form>

</div>
