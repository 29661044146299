import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ApiAccessService } from "../../api-access.service";
import { userRegistration, userRegistrationFailed, userRegistrationSuccessful, userInfo, userInfoFailed, userInfoSuccessful, userSignIn, userSignInFailed, userSignInSuccessful, userSignOut, userSignOutFailed, userSignOutSuccessful } from "./auth.actions";

import { of, from } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Router } from "@angular/router";


@Injectable()
export class AuthEffects {
  constructor(private router: Router, private actions$: Actions, private authService: ApiAccessService) { }

  registerUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userRegistration),
      switchMap((rd) => from(this.authService.registration(rd).pipe(
        map((data) => userRegistrationSuccessful(data)),
        catchError((error) => of(userRegistrationFailed({ error })))))
      )
    )
  );

  userInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userInfo),
      switchMap(() => from(this.authService.user_info().pipe(
        //tap(() => this.router.navigate(["browse-properties"])),
        map((data) => userInfoSuccessful(data)),
        catchError((error) => of(userInfoFailed({ error })))))
      )
    )
  );

  signIn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userSignIn),
      switchMap((data) => from(this.authService.sign_in(data).pipe(
        //tap(() => this.router.navigate(["browse-properties"])),
        map(() => userSignInSuccessful()),
        catchError((error) => of(userSignInFailed({ error })))))
      )
    )
  );

  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userSignOut),
      switchMap(() => from(this.authService.sign_out().pipe(
        map(() => userSignOutSuccessful()),
        catchError((error) => of(userSignOutFailed({ error })))))
      )
    )
  );

  redirectSignout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userSignOutSuccessful),
      tap(() => this.router.navigate([""]))
    )
  }, { dispatch: false }
  );

  redirectToBrowseProperties$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userRegistrationSuccessful, userSignInSuccessful),
        tap(() => this.router.navigate(["browse-properties"]))
      )
    }, {dispatch: false}
  );

  signInSuccessful$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userSignInSuccessful),
        map(() => userInfo()),
      )
    }
  );
}
