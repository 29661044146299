import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ApiAccessService } from '../../api-access.service';
import { _ObjectValue } from '../../models/object-value.model';
import { ShareBought } from '../../models/share-bought.model';
import { SystemConfiguration } from '../../models/system-configuration.model';
import { SystemUser } from '../../models/systemuser.model';
import { AppState } from '../../state/app.state';
import { userInfo } from '../../state/auth/auth.actions';
import { AuthState } from '../../state/auth/auth.reducer';
import { getUser } from '../../state/auth/auth.selectors';
import { ResultComponent } from '../result/result.component';

@Component({
  selector: 'app-invest',
  templateUrl: './invest.component.html',
  styleUrls: ['./invest.component.css']
})
export class InvestComponent implements OnInit {
  @Input() property: _ObjectValue;
  @Output() close_model = new EventEmitter<string>();

  resultDialog: NgbModalRef;

  systemUser: SystemUser;
  systemUser$ = this.state.select(getUser).subscribe(x => {
    this.systemUser = x;
  });

  investForm: FormGroup;
  config: SystemConfiguration;
  busy: boolean = false;

  constructor(private store: Store<AuthState>, private state: Store<AppState>, private modalService: NgbModal, private form_builder: FormBuilder, private apiService: ApiAccessService, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.investForm = new FormGroup({
      amount: new FormControl('', [Validators.required, Validators.max(this.property?.valueJson?.unitsSelling ?? this.property?.valueJson?.remainingShares ?? 0), Validators.min(1), Validators.pattern("^[0-9]*$")])});
    let c = this.apiService.get_configuration(this.property.valueJson?.entitySelling? "Market": "Listing").subscribe(x => {
      if (x?.length) {
        this.config = x[0];
        c.unsubscribe();
      }
    });
  }

  close(message: string) {
    this.close_model.emit(message);
    this.activeModal.close(message);
  }

  submit() {
    if (this.investForm.invalid || this.investForm?.value?.amount == 0) return;
    this.busy = true;

    let s: ShareBought = {
      price: this.property.valueJson.sharePrice,
      property: this.property.entity,
      units: this.investForm.value.amount,
      BuyFrom: this.property.valueJson?.entitySelling?? 0
    };

    if (this.systemUser.balance <= (this.investForm.value.amount * this.property.valueJson.sharePrice)) {
      this.resultDialog = this.modalService.open(ResultComponent, { centered: true, modalDialogClass: "modal-rounded" });
      this.resultDialog.componentInstance.options = {
        button_message: "Continue",
        title: "Lack of Funds!",
        icon_color: "yellow",
        icon_string: "fa-solid fa-circle-exclamation",
        message: "Note not enough in wallet to make this purchase."
      };
      this.busy = false;
    } else {
      this.apiService.buy_share(s).subscribe(x => {
        this.store.dispatch(userInfo());
        this.close("sucessful");
      }, error => {
        this.busy = false;
      });
    }
  }
}
