<div class="page-body">
  <div class="container">
    <div class="third-top">
      <img src="../../../assets/how-it-works/hitn/Group 102.png" class="img-fluid" alt="Responsive image">
      <div class="first-top">
        <div class="row">
          <div class="col-sm-12 first-div">
<div  class="first-divv"><span class="head1" style="font-weight: 900; font-size: 40px; color: #fae977"><b>Start Investing With Us</b></span></div>
          </div>
          <div style="margin-top: 67px; margin-left: 17px;">
            <div class="vl-t_1" style="margin-left:4%">&nbsp;&nbsp;&nbsp;&nbsp;</div>
            <div class="vl-l_1" style="margin-left: 4%;"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="second-top" style="margin-top:50px;">
      <div class="row bb p-1">
       
        <div class="col-sm-2 p-2">
          <img src="../../assets/how-it-works/create-your-account.png" class="d-block w-75" alt="p1" />
          <div class="px"><b>Create your</b></div>
          <div class="px"><b>account</b></div>
        </div>
        <div class="col-sm-2 p-2">
          <img src="../../assets/how-it-works/browser-property-selection.png" class="d-block w-75 " alt="p2" />
          <div class="px"><b>Browse Property</b></div>
          <div class="px" ><b>selection</b></div>
        </div>
        <div class="col-sm-2 p-2">
          <img src="../../assets/how-it-works/buy-property-shares.png" class="d-block w-75 " alt="p3" />
          <div class="px"><b>Buy Property</b></div>
          <div class="px"><b>Shares</b></div>
        </div>
        <div class="col-sm-2 p-2">
          <img src="../../assets/how-it-works/monthly-rental-income.png" class="d-block w-75" alt="p4" />
          <div class="px"><b>Earn Monthly</b></div>
          <div class="px"><b>Rental Income</b></div>
        </div>
        <div class="col-sm-2 p-2 ">
          <img src="../../assets/how-it-works/capital-gains.png" class="d-block w-75" alt="p5" />
          <div class="px"><b>Realise Capital</b></div>
          <div class="px"><b>Gains</b></div>
        </div>
        
      </div>
      <div class="d-element">
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" fill="currentColor" class="bi bi-suit-diamond-fill" viewBox="0 0 16 16" id="IconChangeColor"> <path d="M2.45 7.4 7.2 1.067a1 1 0 0 1 1.6 0L13.55 7.4a1 1 0 0 1 0 1.2L8.8 14.933a1 1 0 0 1-1.6 0L2.45 8.6a1 1 0 0 1 0-1.2z" id="mainIconPathAttribute"></path> </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" fill="currentColor" class="bi bi-suit-diamond-fill" viewBox="0 0 16 16" id="IconChangeColor" style="float: right; margin-top: 8px"> <path d="M2.45 7.4 7.2 1.067a1 1 0 0 1 1.6 0L13.55 7.4a1 1 0 0 1 0 1.2L8.8 14.933a1 1 0 0 1-1.6 0L2.45 8.6a1 1 0 0 1 0-1.2z" id="mainIconPathAttribute"></path> </svg>
      </div>
      <div class="row  bb-2 mt-3">
   
        <div class="col-sm-3 p-3">
          
          <div class="impts" style="display:inline-block; width:100%">
         
            <img style="display:inline-block;" src="../../assets/how-it-works/buy-and-sell-sells.png" class="d-block w-50 " align="left" alt="p1" />
            <p class="py-4" style="font-weight:normal; width:100%"><b>Buy and sell</b> <br /><b>shares at any </b><br /><b>time through</b> <br /><b>our intergrated</b><br /> <b>marketplace</b></p>
          </div>
        </div>
        <div class="col-sm-3 vl p-3">
          <div class="impts" style="display:inline-block;">
            <img style="display:inline-block;" src="../../assets/how-it-works/capital-gains2.png" class="d-block w-50 " align="left" alt="p1" />
            <p class="py-4" style="font-weight:normal"><b>Guaranteed</b>  <br /><b>monthly rental </b> <br /><b>income for the</b>  <br /><b>first year</b></p>
          
          </div>
        
        </div>
        
      </div>
   
    </div>
    <br>

    <!-------------------------Third Last Section----------------------->

    <div class="third-top">
      <img src="../../../assets/how-it-works/hitn/MicrosoftTeams-image.png" class="img-fluid" alt="Responsive image">
      <div class="first-top">
        <div class="row">
          <div class="col-sm-12 first-div2">
            <p class="pttt2" style="font-weight:bolder">We handle the boring stuff <br />so you dont have to</p>
          </div>
        </div>
      </div>
    </div>
    <div class="second-top p-4">
      <div class="row  bb-3">
        

        <div class=" section-2  tab2">
          <img src="../../assets/how-it-works/Vector-4.png" class="d-block w-75" alt="p1" />
        </div>
        <div class=" section-2  tab2">
          <img src="../../assets/how-it-works/Vector-5.png" class="d-block w-75" alt="p1" />
        </div>
        <div class=" section-2  tab2">
          <img src="../../assets/how-it-works/Vector-3.png" class="d-block w-75" alt="p1" />
        </div>
      </div>
      <div class="row bb-3 sec-col">
        <div class="section-2  tab2">
          <img src="../../assets/how-it-works/Vector-2.png" class="d-block w-75" alt="p1" />
        </div>
        <div class="section-2  tab2">
          <img src="../../assets/how-it-works/Vector-1.png" class="d-block w-75" alt="p1" />
        </div>
        <div class="section-2  tab2">
          <img src="../../assets/how-it-works/Vector.png" class="d-block w-75" alt="p1" />
        </div>
      </div>
    </div>

    <!-------------------------Second Last Section----------------------->
    <div class="third-top">
      <img src="../../../assets/how-it-works/hitn/MicrosoftTeams-image.png" class="img-fluid" alt="Responsive image">
      <div class="first-top">
        <div class="row">
          <div class="col-sm-12 first-div3">
            <p class="pttt2" style="font-weight:bolder">Become a property magnate <br /> with our simple process</p>
          </div>
        </div>
      </div>
    </div>
    <div class="second-top p-4">
      <div class="row  bb-3">
        <div class="col-sm-3  tab">
          <img src="../../../assets/how-it-works/hitn/second1.png" class="d-block w-75" alt="p1" />
          <div class="px" ><b>Choose From A</b></div>
          <div class="px" ><b>Diverse Property</b></div>
          <div class="px" ><b>Selection</b></div>
        </div>
        <div class="col-sm-3  tab">
          <img src="../../../assets/how-it-works/hitn/second2.png" class="d-block w-75" alt="p2" />
          <div class="px" ><b>Invest With Any</b></div>
          <div class="px"><b>Amount</b></div>
        </div>
        <div class="col-sm-3  tab">
          <img src="../../../assets/how-it-works/hitn/second3.png" class="d-block w-75" alt="p3" />
          <div class="px" ><b>Earn Monthly</b></div>
          <div class="px" ><b>Rental Income</b></div>
        </div>
        <div class="col-sm-3  tab">
          <img src="../../../assets/how-it-works/hitn/second4.png" class="d-block w-75" alt="p1" />
          <div class="px" ><b>Build And Grow Your</b> </div>
          <div class="px" ><b>Investment Portfolio</b></div>
        </div>
      </div>

    </div>

    <!-------------------------Last Section----------------------->

    <div class="row third-top" style="padding:0;">
      <img src="../../../assets/how-it-works/hitn/MicrosoftTeams-image.png" class="img-fluid" style="padding:0;margin:0;" alt="Responsive image">
      <div class="first-top">
        <div class="row" >
          <div class="col-sm-12  first-div22">
            <div style="margin-left:20%">
              <div class="vl-b_2" style="margin-left:88%;">&nbsp;&nbsp;&nbsp;&nbsp;</div>
              <div class="vl-r_2" style="margin-top:-71px;margin-right:4%;"></div>
            </div>
            <div class="pttt2" style="font-weight:bolder;margin-right:15%;" align="right">We keep our fees low</div>
          </div>
        </div>
      </div>
    </div>
    <div class="second-top p-4" style="background-color:lightgrey;width:100%">

      <div class="row  bb-4">
        <div class="col-sm-5 tab pd">
          <div class="row">
            <div class="col-sm-6  tab" style="padding:0">
              <div class="px"><b>Annual Fee</b></div>
              <img src="../../../assets/how-it-works/annual.svg" width="125" height="140" style="margin-top: -26px; padding-right: 10px; " />
            </div>
            <div class="col-sm-6  tab" style="padding:0">
              <img src="../../../assets/how-it-works/hitn/Frame 156.png" class="img2" width="110" height="125" />
            </div>
          </div>
        </div>
        <div class="col-sm-2 vl-l_2" ></div>
        <div class="col-sm-5 tab">
          <div class="row">
            <div class="col-sm-6  tab pd">
              <div class="px"><b>Transaction</b></div>
              <div class="px" style="text-align:left;padding-left:15px;"> <b>Fee</b></div>
              <img src="../../../assets/how-it-works/tranc.svg" width="115" height="130" style="margin-top: -30px; padding-right: 10px;" />
            </div>
            <div class="col-sm-6  tab" style="padding:0">
              <img src="../../../assets/how-it-works/hitn/Frame 157.png" class="img2" width="110" height="125" />
            </div>
          </div>
        </div>
      </div>

      </div>
  </div>
</div>

