<div class="container" style="font-family: 'Glacial Indifference';">
  <div class="section1">
    <h1><b>My Portfolio</b></h1>
    <div class="col px-3" style="border-left: 4px solid #fae977; margin-top: 35px; padding-bottom: 30px">
      <div class=" property-tab">
        <div class="flex-container property-tile" *ngFor="let property of properties; let i = index;">
          <div class="flex-container" style="width: 100%" *ngIf="i == 0 || property.parentName !== properties[i-1].parentName">
            <div style="width:300px;">
              <img class="property-img" src="{{property.parentImage}}" style="width:100%;height:150px;" alt="{{property.parentName}}" />
            </div>
            <div>
              <div style="margin-top:30px;margin-left:5px;">
                <div><img class="logo-img" src="{{property.parentLogo}}" alt="{{property.parentName}}" /></div><!--<h2><b><span>Stay</span><span style="color:#ff0000">Melville</span>. </b></h2>-->
                <div style="margin-top:5px;"><span style="font-weight:300;"><img class="location-img" src="../../assets/dashboard/icons/Location.svg" /></span><span class="location-address">{{property.address}}</span></div>
              </div>
              <div class="vertical-line"></div>
            </div>
            <div style="margin-top: 30px; margin-left: 5px;">
              <div style="margin-bottom:20px; font-weight:300;">Current Value&nbsp;&nbsp;<span class="span-item3 bold">{{ sum('currentValue', property.parentName) | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</span></div>
              <div><span style="font-weight:300;">Purchase Value</span><span class="span-item3 bold">{{ sum('purchasePrice', property.parentName) | currency: 'ZAR': 'symbol-narrow': '1.2-2' }}</span></div>
            </div>
            <div style="margin-top: 50px; margin-right: 20px;">
              <img *ngIf="toggled == property.parentName" src="../../assets/dashboard/icons/right-arrow-svgrepo-com.svg" style="height:30px;width:30px;" (click)="toggleTile(property.parentName);" />
              <img *ngIf="toggled != property.parentName" src="../../assets/dashboard/icons/down-arrow-of-angle-svgrepo-com.svg" style="height:30px;width:30px;" (click)="toggleTile(property.parentName);" />
            </div>
          </div>
          <div *ngIf="toggled == property.parentName && i > 0 && property.parentName == properties[i-1].parentName" style="width: 100%; background-color: #e9e9e9;">
            <hr />
          </div>
          <div class="toggle-container" *ngIf="toggled == property.parentName" style="font-weight:300;">
            <div class="row">
              <div class="property_name">
                <u>{{property.propertyName}}</u>
              </div>
              <div style="display:flex">
                <div class="col-sm-12">Number of shares owned</div>
                <div class="col-sm-12">{{property.units?? 0 }}</div>
              </div>
              <div style="display:flex">
                <div class="col-sm-12">Expected Rental Income</div>
                <div class="col-sm-12">{{property.expectedRentalIncome?? 0 | currency: 'ZAR': 'symbol-narrow': '1.2-2' }}</div>
              </div>
              <div style="display:flex">
                <div class="col-sm-12">Current Share Price</div>
                <div class="col-sm-12">{{property.currentSharePrice?? 0 | currency: 'ZAR': 'symbol-narrow': '1.2-2' }}</div>
              </div>
              <div style="display:flex">
                <div class="col-sm-12">Current value</div>
                <div class="col-sm-12">{{property.currentValue?? 0 | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</div>
              </div>
              <div style="display:flex">
                <div class="col-sm-12">Purchase Valued</div>
                <div class="col-sm-12">{{property.purchasePrice?? 0 | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</div>
              </div>
            </div>
            <div class="row">
              <div align="right" style="float: right;margin-top:150px;">
                <button id="btn-shares" class="button btn-shares" (click)="sell_shares(property)">Sell Shares</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>

  <div class=" distribution-container" >
    <div class="distribution-container-item1" style="font-weight:300;">
      <div style="font-size:20px;margin-left:11%">Distribution</div>
      <div style="font-size:20px;">Current Value</div>
    </div>
    <div class="table-container property-tile2" *ngFor="let property of properties; let i = index;" style="font-weight: 300; font-size: 20px; height:50px;">
      <div class="distribution-tile table-container " *ngIf="i == 0 || property.parentName !== properties[i-1].parentName">
        <div class="name-border table-container-item1" [style.border-left]="property?.color? ('15px solid ' + property?.color) : '15px solid black'">{{property.parentName}}</div>
        <div class="table-container-item2">{{distribution(property.parentName)?? 0 | percent: '1.0-2'}}</div>
        <div class="table-container-item3">{{sum('currentValue', property.parentName)  | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</div>
      </div>
    </div>
    <div class="table-container horizontal-line"></div>
    <div class="value-container">
      <div style="font-weight: 300; font-size: 20px;">
        <div>Total Value</div>
        <div>Total Value of Portfolio</div>
        <div>Total Purchase Value</div>
        <div>Total Expected Rent per Month</div>
      </div>
      <div class="bottom_price ">
        <div>{{(toggled? sum('currentValue', toggled) : 0)  | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</div>
        <div>{{sum('currentValue') | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</div>
        <div>{{sum('purchasePrice') | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</div>
        <div>{{sum('expectedRentalIncome') | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</div>
      </div>
    </div>
  </div>


  <div class="section1">
    <h1><b>My Market Place</b></h1>
    <div class="col px-3" style="border-left: 4px solid #fae977;margin-top: 35px; padding-bottom: 40px;">
      <div class="property-tab">
        <div class="flex-container property-tile" *ngFor="let property of selling; let i = index;">
          <div class="flex-container" style="width: 100%" *ngIf="i == 0 || property.parentName !== selling[i-1].parentName">
            <div style="width:300px;">
              <img class="property-img" src="{{property.parentImage}}" style="width:100%;height:150px;" alt="{{property.parentName}}" />
            </div>
            <div>
              <div style="margin-top:30px;margin-left:5px;">
                <div><img class="logo-img" src="{{property.parentLogo}}" alt="{{property.parentName}}" /></div><!--<h2><b><span>Stay</span><span style="color:#ff0000">Melville</span>. </b></h2>-->
                <div><span style="font-weight:300;"><img class="location-img" src="../../assets/dashboard/icons/Location.svg" /></span><span style="margin-right:20px;" class="location-address">{{property.address}}</span></div>
              </div>
              <div class="vertical-line"></div>
            </div>
            <div style="margin-top: 30px; margin-left: 5px;">
              <div style="margin-bottom:20px; font-weight:300;">Current Value<span class="span-item3">{{ sum_market('currentValue', property.parentName) | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</span></div>
              <div><span style="font-weight:300;">Listing Date&nbsp;&nbsp;&nbsp;</span><span class="span-item3">{{ property.date | date:'dd/MM/yyyy' }}</span></div>
            </div>
            <div style="margin-top: 50px; margin-right: 20px;">
              <img *ngIf="toggled_market == property.parentName" src="../../assets/dashboard/icons/right-arrow-svgrepo-com.svg" style="height:30px;width:30px;" (click)="toggle_market(property.parentName);" />
              <img *ngIf="toggled_market != property.parentName" src="../../assets/dashboard/icons/down-arrow-of-angle-svgrepo-com.svg" style="height:30px;width:30px;" (click)="toggle_market(property.parentName);" />
            </div>
          </div>
          <div *ngIf="toggled == property.parentName && i > 0 && property.parentName == selling[i-1].parentName" style="width: 100%; background-color: #e9e9e9;">
            <hr />
          </div>
          <div class="toggle-container" *ngIf="toggled_market == property.parentName" style="font-weight:300;">
            <div class="row">
              <div>
                <u>{{property.propertyName}}</u>
              </div>
              <div style="display:flex">
                <div class="col-sm-12">Number of shares for sale</div>
                <div class="col-sm-12">{{property.units?? 0 }}</div>
              </div>
              <div style="display:flex">
                <div class="col-sm-12">Current Share Price</div>
                <div class="col-sm-12">{{property.currentSharePrice?? 0 | currency: 'ZAR': 'symbol-narrow': '1.2-2' }}</div>
              </div>
              <div style="display:flex">
                <div class="col-sm-12"><i>Current value</i></div>
                <div class="col-sm-12">{{property.currentValue?? 0 | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</div>
              </div>
              <div style="display:flex">
                <div class="col-sm-12">Purchase Valued</div>
                <div class="col-sm-12">{{property.purchasePrice?? 0 | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</div>
              </div>
            </div>
            <div class="row">
              <div align="right" style="float: right;margin-top:150px;">
                <button id="btn-remove" class="button btn-shares" style="font-size:0.8rem" (click)="remove_shares(property)">Remove Shares</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
