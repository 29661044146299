import { Component, OnInit } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ApiAccessService } from '../../api-access.service';
import { Transaction } from '../../models/transaction.model';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css']
})
export class TransactionHistoryComponent implements OnInit {

  transactions: Transaction[] = [];

  constructor(private apiService: ApiAccessService) { }

  ngOnInit(): void {
    this.getTransactions();
    window.scrollTo(0, 0);
  }

  getTransactions() {
    this.apiService.getTransactions().subscribe(x => {
          this.transactions = x;
    });
      
  }

}
