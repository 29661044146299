import { createReducer, on } from "@ngrx/store";
import { Alert } from "../../models/alert.model";
import { alertRecieved } from "./system.actions";

export interface SystemState {
  alerts: Alert[]
}

export const initialState: SystemState = {
  alerts: []
};

export const systemReducer = createReducer(
  initialState,
  on(alertRecieved, (state, alert) => ({ ...state, alerts: [...state.alerts, alert] }))
);


