<div class="container">
  <div class="row">
    <div class="col-md-6 align-self-center">
      <img src="../../assets/page-not-found/hand-picked@3x.svg" />
    </div>
    <div class="col-md-6 align-self-center">
      <h1>404</h1>
      <h2>UH OH! You're lost.</h2>
      <p>
        The page you are looking for does not exist.
        How you got here is a mystery.
      </p>
    </div>
  </div>
</div>
