import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../../state/app.state';
import { ResetPassword } from '../../models/reset-password.model';
import { SystemUser } from '../../models/systemuser.model';
import { ApiAccessService } from '../../api-access.service';
import { getUser } from '../../state/auth/auth.selectors';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ResultComponent } from '../result/result.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  @Output()
  close_model = new EventEmitter<string>();
  systemUser: SystemUser;
  resetpasswordForm: FormGroup;

  changepasswordModal: NgbModalRef;
  resultDialog: NgbModalRef;

  busy: boolean = false;

  constructor(private apiService: ApiAccessService, private store: Store<AppState>, private modalService: NgbModal, public activeModal: NgbActiveModal) { }


  openChangePassword() {
    this.changepasswordModal = this.modalService.open(ChangePasswordComponent, { centered: true });
    this.changepasswordModal.componentInstance.user = this.systemUser.id;
    this.close('open change password');
  }

  ngOnInit(): void {
    this.resetpasswordForm = new FormGroup({
      username: new FormControl(),
    });
  }

  req_change_password() {
    if (!this.busy && this.resetpasswordForm.value.username) {
      this.busy = true;
      this.apiService.request_reset_password(this.resetpasswordForm.value.username).subscribe(x => {
        this.busy = false;
        this.systemUser = x.user;
        this.openChangePassword();
      }, fail => {
        this.busy = false;
        this.resultDialog = this.modalService.open(ResultComponent, { centered: true, modalDialogClass: "modal-rounded" });
        this.resultDialog.componentInstance.options = {
          button_message: "Return",
          title: "Invalid Unsername!",
          icon_color: "yellow",
          icon_string: "fa-solid fa-circle-exclamation",
          message: "Please chose a valid username to change your password."
        };
      });
    }
  }

  close(message: string) {
    this.close_model.emit(message);
    this.activeModal.close(message);
  }
}
