import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../dialogs/contact-us/contact-us.component';
import { ResultComponent } from '../dialogs/result/result.component';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class contactUsComponent implements OnInit {
  contact_usModal: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  Contact_usForm: FormGroup;
  resultDialog: NgbModalRef;

  busy: boolean = false;

  ngOnInit(): void {
    this.Contact_usForm = new FormGroup({
      username: new FormControl(),
      email: new FormControl('', [Validators.pattern('^[a-z0-9A-Z._-]+@[a-zA-Z0-9_-]+[.]{1,1}[a-zA-Z0-9._-]{2,3}$')]),
      message: new FormControl(),
    });
  }

  submit_form() {
    if (this.Contact_usForm.invalid) return;
    this.busy = true;
    this.resultDialog = this.modalService.open(ResultComponent, { centered: true, modalDialogClass: "modal-rounded" });
    this.resultDialog.componentInstance.options = {
      button_message: "Return",
      title: "Under Construction!",
      icon_color: "yellow",
      icon_string: "fa-solid fa-circle-exclamation",
      message: "Note withdrawal process is still under construction"
    };
    this.resultDialog.result.then(pass => {
      this.busy = false;
    }, fail => {
      this.busy = false;
    });
   
  }

  openContact_us() {
    this.contact_usModal = this.modalService.open(ContactUsComponent, { centered: true });
  }

 

}
