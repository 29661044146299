import { Component, Inject, OnInit } from '@angular/core';
import {  FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { userSignOut } from '../state/auth/auth.actions';
import { getUser } from '../state/auth/auth.selectors';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SystemUser } from '../models/systemuser.model';
import { ApiAccessService } from '../api-access.service';
import { LoginComponent } from '../dialogs/login/login.component';
import { RegisterComponent } from '../dialogs/register/register.component';
import { ContactUsComponent } from '../dialogs/contact-us/contact-us.component';
import { ChangePasswordComponent } from '../dialogs/change-password/change-password.component';
import { ResultComponent } from '../dialogs/result/result.component';


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
  host: { 'class': 'd-flex flex-column position-relative'}
})
export class NavMenuComponent {
  isExpanded = false;
  isPropertiesExpanded = false;
  isAboutExpanded = false;
  systemUser$ = this.store.select(getUser);
  systemUser: SystemUser;
  loginModal: NgbModalRef;
  registerModal: NgbModalRef;
  contact_usModal: NgbModalRef;
  resultDialog: NgbModalRef;
  resetpasswordForm: FormGroup;

  changepasswordModal: NgbModalRef;

  constructor(private apiService: ApiAccessService, private store: Store<AppState>, private modalService: NgbModal, private form_builder: FormBuilder, @Inject("BASE_URL") private base_url: string) { }

  openLogin() {
    this.loginModal = this.modalService.open(LoginComponent, { centered: true });
  }

  openRegister() {
    this.registerModal = this.modalService.open(RegisterComponent, { centered: true });
  }

  openContact_us() {
    this.contact_usModal = this.modalService.open(ContactUsComponent, { centered: true});
  }

  openChangePassword(user) {
    if (user.username) {
      this.apiService.request_reset_password(user.username).subscribe(x => {
        this.changepasswordModal = this.modalService.open(ChangePasswordComponent, { centered: true });
        this.changepasswordModal.componentInstance.user = this.systemUser.id;
      }, fail => {
        this.resultDialog = this.modalService.open(ResultComponent, { centered: true, modalDialogClass: "modal-rounded" });
        this.resultDialog.componentInstance.options = {
          button_message: "Return",
          title: "Not Signed In!",
          icon_color: "yellow",
          icon_string: "fa-solid fa-circle-exclamation",
          message: "Please note you are no longer signed in and need to refresh the page."
        };
      });
    }
  }

  get_photo(user?: SystemUser) {
    if (user?.photo) {
      return user?.photo;
    } else if (user?.entity) {
      //window.api_url + "/entity/" + entity.Id + "/photo"
      return this.base_url + "/entity/" + user.entity + "/photo";
    } else {
      return "../../assets/nav-menu/user (1).png";
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  collapse() {
    this.isExpanded = false;
  }

  collapseAbout() {
    this.isAboutExpanded = false;
  }
  collapseProperties() {
    this.isPropertiesExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  toggleAbout() {
    this.isAboutExpanded = !this.isAboutExpanded;
  }
  toggleProperties() {
    this.isPropertiesExpanded = !this.isPropertiesExpanded;
  }

  logout() {
    this.store.dispatch(userSignOut());
  }
}
