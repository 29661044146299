import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationData } from '../../models/registration-data.model';
import { ApiAccessService } from '../../api-access.service';
import { LoginComponent } from '../login/login.component';
import { AppService } from '../../app-service';
import { ChangePasswordComponent } from '../change-password/change-password.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  @Output()
  close_model = new EventEmitter<string>();

  registerForm: FormGroup;
  loginModal: NgbModalRef;
  changePasswordModal: NgbModalRef;

  busy: boolean = false;
  is_login_open: boolean = false;


  constructor(private modalService: NgbModal, private apiService: ApiAccessService, public activeModal: NgbActiveModal, private appService: AppService) { }


  ngOnInit() {
    this.appService.set_display(false);
    this.registerForm = new FormGroup({
      username: new FormControl(),
      email: new FormControl('', [Validators.required, Validators.email]),
      //password1: new FormControl('', [Validators.required]),
      //password2: new FormControl(),
      //  isAccepted: new FormControl('', Validators.requiredTrue)
    });

    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    if (!this.is_login_open) {
      this.appService.set_display(true);
    }
  }

  submit_registerform(data) {
    let result: RegistrationData = { email: this.registerForm.value.email, firstNames: this.registerForm.value.username, memberNo: this.registerForm.value.username, IncludeOTP: true };
    this.busy = true;
    let r = this.apiService.registration(result).subscribe(x => {
      this.busy = false;

      this.changePasswordModal = this.modalService.open(ChangePasswordComponent, { centered: true });
      this.changePasswordModal.componentInstance.user = x.id;
      this.changePasswordModal.componentInstance.from_registration = true;
      this.close("Register Complete");
    }, error => {
      this.busy = false;
    });
  }

  get username() { return this.registerForm.get('username'); }
  get email() { return this.registerForm.get('email'); }
  //get password1() { return this.registerForm.get('password1'); }
  

  openLogin() {
    this.is_login_open = true;
    this.loginModal = this.modalService.open(LoginComponent, { centered: true });
    this.appService.set_display(true);
    this.close('open login');
  }

  close(message: string) {
    this.close_model.emit(message);
    this.activeModal.close(message);
  }
}
