<div style="background: #000000; padding: 10px;">
  <div align="right" style="float:right">
    <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="close('closed')"></button>
  </div>
  <div style=" text-align: center;height:100px; display:flex; align-items: center; justify-content:center;">
    <img src="../../assets/nav-menu/PMLogo4.png" alt="Responsive image" style="width: 200px; padding-top: 0px;" />
  </div>

  <form [formGroup]="changepasswordForm" class="form" style="background: #000000; font-family: 'Glacial Indifference'; height: 450px;" autocomplete="off">
    <div style="text-align:center;">
      <div style="font-size:20px;color:white;">Change your password</div>
      <div style="font-size:12px;color:white;">We have sent an OTP to your preferred email address</div>
      <div style="font-size:12px;color:white;">Please enter the OTP you have received below:</div>
    </div>

    <div class="form-group">
      <div style="font-size: 15px; color: white; margin-left: 10%">OTP</div>
      <div style="width:250px;margin-left:10%">
        <input autofocus="" class="form-group__control ng-pristine ng-invalid ng-touched" style="height: 35px;" formControlName="otp" required type="text" autocomplete="new-otp">
      </div>
      <div style="margin-left:10%" class="invalid-feedback" *ngIf="changepasswordForm.controls.otp.errors?.['required']">
        OTP is required
      </div>
    </div>
    <div class="form-group">
      <div style="font-size: 15px; color: white; margin-left: 10%">New password</div>
      <div style="width: 100%; margin-left: 10%; display: flex; flex-direction: row;align-items:center">
        <input autofocus="" style="width: 310px !important;height: 35px;" class="credential-input form-group__control ng-pristine ng-invalid ng-touched" formControlName="newpassword" required [type]="password1" pattern="^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$" autocomplete="new-newpassword">
        <div style="justify-content:center; align-items:center;display:flex; width:80px;padding-top:5px"  (click)="onClick(1)">
          <i class="fa fa-eye" style="color: white;" aria-hidden="true" *ngIf="!show1"></i>
          <i class="fa fa-eye-slash" style="color: white;" aria-hidden="true" *ngIf="show1"></i>
        </div>
      </div>
      <div style="margin-left:10%" class="invalid-feedback" *ngIf="changepasswordForm.controls.newpassword?.invalid && (changepasswordForm.controls.newpassword?.dirty || changepasswordForm.controls.newpassword?.touched)">
        <div *ngIf="changepasswordForm.controls.newpassword?.errors?.pattern">
          Password should have minimum 8 characters, at least 1 uppercase letter, 1 lowercase
          letter and 1 number.
        </div>
      </div>
      <!--<div style="margin-left:10%" class="invalid-feedback" *ngIf="changepasswordForm.controls.newpassword.errors?.['required']">
      Password is required
    </div>-->
    </div>
    <div class="form-group">
      <div style="font-size: 15px; color: white; margin-left: 10%">Confirm password</div>
      <div style="width:100%;margin-left:10%;display:flex;flex-direction:row; align-items:center;">
        <input autofocus="" style="width: 310px !important; height: 35px;" class="credential-input form-group__control ng-pristine ng-invalid ng-touched" formControlName="confirmpassword" required [type]="password2" autocomplete="new-confirmpassword">
        <div style="justify-content:center; align-items:center;display:flex; width:80px;padding-top:5px" (click)="onClick(2)">
          <i class="fa fa-eye" style="color: white;" aria-hidden="true" *ngIf="!show2"></i>
          <i class="fa fa-eye-slash" style="color: white;" aria-hidden="true" *ngIf="show2"></i>
        </div>
      </div>
      <div style="margin-left:10%" class="invalid-feedback" *ngIf="changepasswordForm.controls.confirmpassword?.invalid && (changepasswordForm.controls.confirmpassword?.dirty || changepasswordForm.controls.confirmpassword?.touched)">
        <div *ngIf="changepasswordForm.controls.confirmpassword?.errors?.pattern">
          Password is required.
        </div>
        <div *ngIf="changepasswordForm.controls.confirmpassword?.errors?.confirmedValidator">
          Password and confirm password must be match.
        </div>
      </div>

      <!--<div style="margin-left:10%" class="invalid-feedback" *ngIf="changepasswordForm.controls.confirmpassword.errors?.['required']">
      Confirm Password is required
    </div>-->
    </div>
    <br />
    <div class="center pb-5 ">
      <button type="button" class="btn btn-primary" [disabled]="!changepasswordForm.valid || busy" (click)="reset_password()"><span>Reset Password</span></button>
    </div>
  </form>
</div>

