<header class="nav-header p-x bg-light text-white" style="height: 55px">
  <nav class="navbar navbar-expand-xl navbar-dark">
    <div class="container">
      <div class="nav-logo ">
        <a [routerLink]="['/']">
          <img src="../../assets/nav-menu/PMLogo4.png" alt="Responsive image" style="width: 150px;" />
        </a>
      </div>

      <button class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarCollapse" >

        
          <ul class="navbar-nav nav-loggedIn flex-fill justify-content-md-center mb-2 mb-md-0"  #link id="browse-properties" *ngIf="systemUser$ | async as user else signedInNav">
            <li class="nav-item1 ">
              <a class="nav-link" [routerLink]="['/dashboard']" (click)="link.id='dashboard'" [style.font-weight]="link.id=='dashboard'?'900':'100'">Dashboard</a>
            </li>
            <li class="nav-item1">
              <a class="nav-link" [routerLink]="['/browse-properties']" (click)="link.id='browse-properties'" [style.font-weight]="link.id=='browse-properties'?'bold':'normal'">Browse Properties</a>
            </li>
            <li class="nav-item1">
              <a class="nav-link" [routerLink]="['/contact-us']" (click)="link.id='contact-us'" [style.font-weight]="link.id=='contact-us'?'bold':'100'">Contact Us</a>
            </li>
          </ul>
        

        <div class="text-end" *ngIf="systemUser$ | async as user else notSignedIn"></div>

        <div class="nav-btn">
          <ng-template #notSignedIn class="text-end">
            <button type="button" class="btn logbtn btn-outline-light" (click)="openLogin()" style="margin-right:16px; ">Login</button>
            <button type="button" class="btn regbtn btn-primary me-2" (click)="openRegister()" style="margin-right:16px; ">Register</button>
          </ng-template>
        </div>
      </div>
      
        <div class="nav-menu1 row" *ngIf="systemUser$ | async as user">
          <div class="nav-item nav-wallet dropdown" style="text-align: left; padding-top: 5px;width:50%" [routerLinkActive]="['link-active']">

            <a class="nav-link dropdown-toggle" href="#" id="navbarAbout" role="button" data-bs-toggle="dropdown" [attr.aria-expanded]="isAboutExpanded">
              <img class="nav-icons2" src="../../assets/nav-menu/wallet.png" height="25" width="25" />
              <img class="nav-icons1" src="../../assets/nav-menu/wallet1.png" height="25" width="25" />
              <i class="ptt5">  R {{user?.balance | number : '.2'}}</i>

            </a>
            <ul class="dropdown-menu dropdown-menu-wallet" aria-labelledby="navbarAbout">
              <li>
                <a class="dropdown-item nav-icons" style="display:none" [routerLinkActive]="['link-active']" [routerLink]="['/wallet/fund-this-account']">
                  <svg class="nav-icons nav-icons-fill" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 23" fill="none">
                    <path d="M27.0833 0H0V18.75H27.0833V0ZM25 16.875H2.08333V1.875H25V16.875Z" fill="black" stroke-width="0.4" stroke="#000000" />
                    <path d="M29.1668 4.6875V20.625H5.2085V22.5H31.2502V4.6875H29.1668Z" fill="black" stroke-width="0.4" stroke="#000000" />
                    <path d="M13.5415 13.9723C16.1261 13.9723 18.229 11.9135 18.229 9.38285C18.229 6.85225 16.1261 4.79344 13.5415 4.79344C10.9569 4.79344 8.854 6.85219 8.854 9.38285C8.854 11.9135 10.9569 13.9723 13.5415 13.9723ZM13.5415 6.66844C14.9774 6.66844 16.1457 7.88613 16.1457 9.38285C16.1457 10.8796 14.9774 12.0973 13.5415 12.0973C12.1056 12.0973 10.9373 10.8796 10.9373 9.38285C10.9373 7.88613 12.1056 6.66844 13.5415 6.66844ZM4.1665 4.21875H6.24984V14.5312H4.1665V4.21875ZM20.8332 4.21875H22.9165V14.5312H20.8332V4.21875Z" fill="black" stroke-width="0.3" stroke="#000000" />
                  </svg>
                  Fund this account
                </a>
              </li>
              <li>
                <a class="dropdown-item nav-icons" [routerLinkActive]="['link-active']" [routerLink]="['/wallet/withdraw-money']">
                  <svg class="nav-icons nav-icons-fill " id="changeColor" fill="#DC7633" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" zoomAndPan="magnify" viewBox="0 0 375 374.9999" width="24" height="24" version="1.0"><defs><path id="pathAttribute" d="M 11.972656 11.972656 L 359.222656 11.972656 L 359.222656 359.222656 L 11.972656 359.222656 Z M 11.972656 11.972656 " fill="#000000" stroke-width="0.9" stroke="#000000"></path></defs><g><path id="pathAttribute" d="M 185.597656 359.222656 C 89.675781 359.222656 11.972656 280.984375 11.972656 185.597656 C 11.972656 90.210938 89.675781 11.972656 185.597656 11.972656 C 281.519531 11.972656 359.222656 89.675781 359.222656 185.597656 C 359.222656 281.519531 280.984375 359.222656 185.597656 359.222656 Z M 185.597656 22.691406 C 95.570312 22.691406 22.691406 95.570312 22.691406 185.597656 C 22.691406 275.625 95.570312 348.503906 185.597656 348.503906 C 275.625 348.503906 348.503906 275.625 348.503906 185.597656 C 348.503906 95.570312 275.089844 22.691406 185.597656 22.691406 Z M 185.597656 22.691406 " fill-opacity="1" fill-rule="nonzero" fill="#000000" stroke-width="1" stroke="#000000"></path></g><g id="inner-icon" transform="translate(85, 75)"> <svg xmlns="http://www.w3.org/2000/svg" width="209" height="209" fill="currentColor" class="bi bi-arrow-down" viewBox="0 0 16 16" id="IconChangeColor"> <path fill-rule="evenodd" d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z" id="mainIconPathAttribute" stroke-width="0.7" stroke="#000000"></path> </svg> </g></svg>
                  Withdraw money
                </a>
              </li>
              <li>
                <a class="dropdown-item nav-icons" [routerLinkActive]="['link-active']" [routerLink]="['/wallet/transaction-history']">
                  <svg class="nav-icons nav-icons-fill " id="changeColor" fill="#DC7633" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" zoomAndPan="magnify" viewBox="0 0 375 374.9999" height="24" version="1.0"><defs><path id="pathAttribute" d="M 11.972656 11.972656 L 359.222656 11.972656 L 359.222656 359.222656 L 11.972656 359.222656 Z M 11.972656 11.972656 " fill="#000000" stroke-width="0.9" stroke="#000000"></path></defs><g><path id="pathAttribute" d="M 185.597656 359.222656 C 89.675781 359.222656 11.972656 280.984375 11.972656 185.597656 C 11.972656 90.210938 89.675781 11.972656 185.597656 11.972656 C 281.519531 11.972656 359.222656 89.675781 359.222656 185.597656 C 359.222656 281.519531 280.984375 359.222656 185.597656 359.222656 Z M 185.597656 22.691406 C 95.570312 22.691406 22.691406 95.570312 22.691406 185.597656 C 22.691406 275.625 95.570312 348.503906 185.597656 348.503906 C 275.625 348.503906 348.503906 275.625 348.503906 185.597656 C 348.503906 95.570312 275.089844 22.691406 185.597656 22.691406 Z M 185.597656 22.691406 " fill-opacity="2" fill-rule="nonzero" fill="#000000" stroke-width="1" stroke="#000000"></path></g><g id="inner-icon" transform="translate(85, 75)"> <svg xmlns="http://www.w3.org/2000/svg" width="211" height="211" fill="currentColor" class="bi bi-arrow-left-right" viewBox="0 0 16 16" id="IconChangeColor"> <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z" stroke-width="0.7" stroke="#000000" id="mainIconPathAttribute"></path> </svg> </g></svg>
                  Transaction history
                </a>
              </li>
            </ul>
          </div>

          <div class="navv dropdown dropdown-user-profile" style="text-align:right;" [routerLinkActive]="['link-active']">
            <a class="nav-link  dropdown-toggle" style="color: #fae977; " href="#" id="navbarAbout" role="button" data-bs-toggle="dropdown" [attr.aria-expanded]="isAboutExpanded">
              <img class="nav-icons" src="{{get_photo(user)}}" height="27" width="30" />
            </a>
            <ul class="dropdown-menu dropdown-menu-profile" aria-labelledby="navbarAbout">
              <li><a class="dropdown-item" [routerLinkActive]="['link-active']" [routerLink]="['/account/my-profile']">My profile</a></li>
              <li><a class="dropdown-item" (click)="openChangePassword(user);">Change password</a></li>
              <li><a class="dropdown-item" [routerLinkActive]="['link-active']" (click)="logout()">Logout</a></li>
            </ul>
          </div>
        </div>
      </div>
    
  </nav>

  

  <ng-template #signedInNav>
    <ul class="navbar-nav flex-fill justify-content-md-center mb-2 mb-md-0" #link2>
      <li class="nav-item" [routerLinkActive]="['link-active']">
        <a class="nav-link" [routerLink]="['/how-it-works']" (click)="link2.id='how-it-works'" [style.font-weight]="link2.id=='how-it-works'?'900':'100'">How It Works</a>
      </li>
      <li class="nav-item" [routerLinkActive]="['link-active']">
        <a class="nav-link" [routerLink]="['/browse-properties']" (click)="link2.id='browse-properties2'" [style.font-weight]="link2.id=='browse-properties2'?'900':'100'">Browse Properties</a>
      </li>
      <li class="nav-item" [routerLinkActive]="['link-active']">
        <a class="nav-link" [routerLink]="['/about-us']" (click)="link2.id='about-us'" [style.font-weight]="link2.id=='about-us'?'900':'100'">About Us</a>
      </li>
    </ul>
  </ng-template>
</header>


