<div class="container" style="font-family: 'Glacial Indifference';">
  <h1>Withdraw Funds</h1>
  <form [formGroup]="withdrawForm" (ngSubmit)="onSubmit()">
    <div class="card">
      <div class="space-between">
        <div class="bold">From Account</div>
        <div>
          <select class="form-select  mb-3" id="fromAccount" formControlName="fromAccount">
            <option selected disabled value="">Select Account</option>
            <option *ngFor="let a of accounts" [ngValue]="a">
              {{ a.Name }}
            </option>
          </select>
        </div>
      </div>
      <div class="center-row">
        <div class="amount">
          Amount to Withdraw
        </div>
      </div>
      <div class="center-row">
        <div>
          <span>R </span>
          <input type="number" id="amount" formControlName="amount">
        </div>
      </div>
      <div *ngIf="withdrawForm.invalid && (withdrawForm.controls.amount?.dirty || withdrawForm.controls.amount?.touched)" class="alert">
        <div *ngIf="withdrawForm.controls.amount.errors?.['required']">
          Amount is required.
        </div>
        <div *ngIf="withdrawForm.controls.amount.errors?.['min']">
          Amount must be between R0.01 and Rx.xx
        </div>
      </div>
      <div class="space-between">
        <div class="bold">To Account</div>
        <div>
          <select class="form-select  mb-3" id="toAccount" formControlName="toAccount">
            <option selected disabled value="">Select Account</option>
            <option *ngFor="let a of accounts" [ngValue]="a">
              {{ a.Name }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="btn-row">
      <button (click)="onCancel()" [disabled]="busy"  class="btn-cancel">
        Cancel
      </button>
      <button type="submit" [disabled]="!withdrawForm.valid || busy" class="btn-withdraw">
        Withdraw
      </button>
    </div>
  </form>
  <div class="info-note">
    <h5>PLEASE NOTE</h5>
    <p>Confirmed transactions cannot be reversed or cancelled.</p>
    <p>Requests received <strong>before 2pm MON - FRI</strong>, will be released from out Property Magnate account by 4pm</p>
    <p>Requests received <strong>after 2pm MON - FRI</strong>, as well as requests received on a weekend/<br />public holidays, will only be released the following business day</p>
  </div>
</div>




