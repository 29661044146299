
<div *ngIf="property">
  <div class="px-4 py-5 text-center header_image justify-content-end" [ngStyle]="{ 'background-image' : 'url(' + property.entity.photoUri + ')'}" data-bs-toggle="modal" data-bs-target="#galleryModal">
    <button type="button" data-bs-toggle="modal" data-bs-target="#galleryModal" class="view-prop-btn btn-block">View Photos</button>
  </div>
  <div class="modal modal-fullscreen" tabindex="-1" id="galleryModal" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content" style="background-color:transparent;">
        <div class="modal-body">
          <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="{{property.entity.photoUri}}" class="d-block w-100 img-gallery" alt="...">
              </div>
              <div class="carousel-item" *ngFor="let i of property.attachments">
                <img src="{{i.path}}" class="d-block w-100 img-gallery" alt="...">
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
        <div class="btn-row">
          <button class="btn-return" data-bs-dismiss="modal" type="button">Return</button>
        </div>
      </div>
    </div>
  </div>
  <!--[ngStyle]="{ 'background-image' : 'url(' + property.entity.photoUri + ')'}"-->
  <nav class="grey">
    <div class="nav nav-tabs" id="nav-tab" role="tablist">
      <button class="nav-link active fs-4" id="nav-invest-tab" data-bs-toggle="tab" data-bs-target="#nav-invest" type="button" role="tab" aria-controls="nav-home" aria-selected="true" style="font-weight:bolder;font-family:'Glacial Indifference'">Invest</button>
      <button class="nav-link  fs-4" id="nav-description-tab" data-bs-toggle="tab" data-bs-target="#nav-description" type="button" role="tab" aria-controls="nav-description" aria-selected="false" style="font-weight:bolder;font-family:'Glacial Indifference'">Description</button>
      <button class="nav-link  fs-4" id="nav-document-tab" data-bs-toggle="tab" data-bs-target="#nav-document" type="button" role="tab" aria-controls="nav-document" aria-selected="false" style="font-weight:bolder;font-family:'Glacial Indifference'">Documents</button>
      <button class="nav-link  fs-4" id="nav-floorplan-tab" data-bs-toggle="tab" data-bs-target="#nav-floorplan" type="button" role="tab" aria-controls="nav-floorplan" aria-selected="false" style="font-weight:bolder;font-family:'Glacial Indifference'">Floor Plan </button>
    </div>
  </nav>
  <div class="tab-content" id="nav-tabContent" *ngIf="property.entityObject?.objValue?.valueJson as property_info">
    <!------------------FIRST TAB---------------------->
    <div class="tab-pane fade show active" id="nav-invest" role="tabpanel" aria-labelledby="nav-home-tab">

      <div class="row main-main mt-2">
        <div class="col-xl-8">
          <div class="row">
            <div class="logo col-lg-12"><img class="img-logo" src="{{property_info.logo}}" alt="{{property.entity.name}}" /></div>
            <div class="row grey2">
              <div class="col-sm-6 fluidity"><span><img src="../../../assets/logo/Invest/icons8-home.svg" class="img-fluid" alt="Responsive image" style=" padding-left: 10px;"></span><span class="text" style="font-weight: bold; font-family: 'Glacial Indifference';font-size:20px;">{{property.entityObject.obj.name}}</span></div>
              <div class="col-sm-6 fluidity"><span><img src="../../../assets/logo/Invest/location-pin-svgrepo-com.svg" class="img-fluid" alt="Responsive image"></span><span class="text" style="font-weight:bold; font-family:'Glacial Indifference'">{{property.mainAddress?.addressTextShortHand}}</span></div>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-xl-12 table-container">
              <table class="table table-xs  table-borderless" >
                <tbody>
                  <tr *ngFor="let child of property.entityObject?.children[0]?.data" (click)="select_property(child)" >
                    <td>
                      <div *ngIf="is_for_market" class="row grey block1" style="margin-left: 30px;">
                        <div style="cursor:pointer" class="col-sm-3 fs-5 greyyy" [style.background-color]="selected_child?.id == child.id? (property_info?.color? property_info.color : '#caa808') : 'darkgrey'">{{ child.valueJson.fullName }}</div>
                        <div class="value col-sm-2" style=" padding: 0;"><p class="rb" style="font-size:12px">{{ is_for_market? child?.valueJson?.unitsSelling : (progress((child?.valueJson?.remainingShares? (child?.valueJson?.totalShares?? 0) - (child?.valueJson?.remainingShares?? 0) : 0), child?.valueJson?.totalShares?? 0)  | percent: '1.0-2') }} <br />{{is_for_market? 'Units' : 'Sold'}}</p></div>
                        <div class="col-sm-7  ">
                          <div class="row" >
                            <div class="seller">Seller:{{child.valueJson.entitySellingName}}</div>
                            <div class="value1 col-sm-7" style=" padding: 0;"><p class="rb" style="padding-top: 6px;">{{ child?.valueJson?.valuation ?? 0 | currency: 'ZAR': 'symbol-narrow': '1.2-2' }}</p></div>
                            <div class="value1 col-sm-5"><p style="text-align: center; padding-top: 3px;">{{ child?.valueJson?.expectedRentalIncome ?? 0 | currency: 'ZAR': 'symbol-narrow': '1.0-0'}}</p></div>
                          </div>
					    </div>
                      </div>
                      <div *ngIf="!is_for_market" class="row grey" style="margin-left: 30px;">
                        <div style="cursor:pointer" class="col-sm-3 fs-5 greyyy" [style.background-color]="selected_child?.id == child.id? (property_info?.color? property_info.color : '#caa808') : 'darkgrey'">{{ child.valueJson.fullName }}</div>
                        <div class="value col-sm-2" style=" padding: 0;"><p class="rb" style="font-size:12px">{{ is_for_market? child?.valueJson?.unitsSelling : (progress((child?.valueJson?.remainingShares? (child?.valueJson?.totalShares?? 0) - (child?.valueJson?.remainingShares?? 0) : 0), child?.valueJson?.totalShares?? 0)  | percent: '1.0-2') }} <br />{{is_for_market? 'Units' : 'Sold'}}</p></div>
                        <div class="col-sm-7  ">
                          <div class="row">
                            <div class="value1 col-sm-7" style=" padding: 0;"><p class="rb" style="padding-top: 6px;">{{ child?.valueJson?.valuation ?? 0 | currency: 'ZAR': 'symbol-narrow': '1.2-2' }}</p></div>
                            <div class="value1 col-sm-5"><p style="text-align: center; padding-top: 3px;">{{ child?.valueJson?.expectedRentalIncome ?? 0 | currency: 'ZAR': 'symbol-narrow': '1.0-0' }}</p></div>
                            <div class="row" style="margin-top: -5px; margin-left: 30px">
                              <div class="col-3"></div>
                              <div class="progress-child col-8">
                                <div role="progressbar" [style.background-color]="property_info?.color? property_info?.color : '#caa808'" aria-label="" [ngStyle]="{'width': (progress((child?.valueJson?.remainingShares? (child?.valueJson?.totalShares?? 0) - (child?.valueJson?.remainingShares?? 0) : 0), child?.valueJson?.totalShares?? 0) * 100) + '%'}" [aria-valuenow]="property.percentage" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-lg-4 d-flex justify-content-start" style="margin-top:20px" *ngIf="selected_child">
          <div class="card mb-3 " style="max-width: 100%; width:95%">
            <div class="card-header" style="background-color: lightgrey; height: 30%;">
              <div class="row">
                <h1>{{selected_child.valueJson.fullName}}</h1>
                <div class="col-sm-12 progress-percentage1">
                  <div *ngIf="is_for_market" class="center">{{ is_for_market? selected_child?.valueJson?.unitsSelling : (progress((selected_child?.valueJson?.remainingShares? (selected_child?.valueJson?.totalShares?? 0) - (selected_child?.valueJson?.remainingShares?? 0) : 0), selected_child?.valueJson?.totalShares?? 0)   | percent: '1.0-2')  }} Units </div>

                  <div *ngIf="!is_for_market" >
                    <div class="row">
                      <div class="col-sm-2 progress-percentage">
                        <div>{{ is_for_market? selected_child?.valueJson?.unitsSelling : (progress((selected_child?.valueJson?.remainingShares? (selected_child?.valueJson?.totalShares?? 0) - (selected_child?.valueJson?.remainingShares?? 0) : 0), selected_child?.valueJson?.totalShares?? 0)   | percent: '1.0-2')  }} </div>
                        <div>{{is_for_market? 'Units' : 'SOLD'}}</div>
                      </div>

                      <div class="d-flex align-items-center col-sm-10">
                        <div class="progress w-100">
                          <div class="progress-bar" role="progressbar" [style.background-color]="property_info?.color? property_info?.color : '#caa808'" aria-label="" [ngStyle]="{'width': (progress((selected_child?.valueJson?.remainingShares? (selected_child?.valueJson?.totalShares?? 0) - (selected_child?.valueJson?.remainingShares?? 0) : 0), selected_child?.valueJson?.totalShares?? 0) * 100) + '%'}" [aria-valuenow]="property.percentage" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                      <!--<progress class="col-sm-9" value="" max="100">{{progress(selected_child?.valueJson?.remainingShares?? 0, selected_child?.valueJson?.totalShares?? 0)   | percent: '1.0-2' }}</progress>-->
                    </div>
                    <!--<ngx-slider [(value)]="value" [options]="options"></ngx-slider>-->
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body" style="width:100%;padding-bottom:0">
              <!--------------------------------------------------->
              <div class="row mb-2">
                <div class="col-sm-7"> <div class="text2">Valuation</div></div>

                <div class="col-sm-5"><div class="d-flex justify-content-end"><p><strong>{{selected_child?.valueJson?.valuation?? 0 | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</strong><i [style.color]="property_info?.color? property_info?.color : '#caa808'" class="fa fa-question-circle" aria-hidden="true"></i></p></div></div>
              </div>
              <!--------------------------------------------------->
              <div class="row mb-2">
                <div class="col-sm-7"> <div class="text2">{{ is_for_market? 'Available Shares' : 'Remaining Shares'}}</div></div>

                <div class="col-sm-5"><div class="d-flex justify-content-end"><p><strong>{{is_for_market? (selected_child?.valueJson?.unitsSelling?? 0) : (selected_child?.valueJson?.remainingShares?? 0) }}</strong><i [style.color]="property_info?.color? property_info?.color : '#caa808'" class="fa fa-question-circle" aria-hidden="true"></i></p></div></div>
              </div>
              <!--------------------------------------------------->
              <div class="row mb-2">
                <div class="col-sm-7"> <div class="text2">Share Price</div></div>

                <div class="col-sm-5"><div class="d-flex justify-content-end"><p><strong>{{selected_child?.valueJson?.sharePrice?? 0  | currency: 'ZAR': 'symbol-narrow': '1.2-2'}}</strong><i [style.color]="property_info?.color? property_info?.color : '#caa808'" class="fa fa-question-circle" aria-hidden="true"></i></p></div></div>
              </div>
              <!--------------------------------------------------->
              <div class="row mb-2">
                <div class="col-sm-7"> <div class="text2">Exp Rental Income</div></div>

                <div class="col-sm-5"><div class="d-flex justify-content-end"><p><strong>{{selected_child?.valueJson?.expectedRentalIncome?? 0 | currency: 'ZAR': 'symbol-narrow': '1.0-0'}}</strong><i [style.color]="property_info?.color? property_info?.color : '#caa808'" class="fa fa-question-circle" aria-hidden="true"></i></p></div></div>
              </div>
              <!--------------------------------------------------->
              <div class="row mb-2">
                <div class="col-sm-7"> <div class="text2">Exp Rental Yield</div></div>

                <div class="col-sm-5"><div class="d-flex justify-content-end"><p><strong>{{selected_child?.valueJson?.expectedRentalYield?? 0 | number: '1.0-2'}} %</strong><i [style.color]="property_info?.color? property_info?.color : '#caa808'" class="fa fa-question-circle" aria-hidden="true"></i></p></div></div>
              </div>
              <!--------------------------------------------------->
              <div class="row">
                <div class="col-sm-8"> <div class="d-flex justify-content-start text2">Blended Internal Rate of Return</div></div>

                <div class="col-sm-4"><div class="d-flex justify-content-end"><p><strong>{{selected_child?.valueJson?.rateOfReturn?? 0 | number: '1.0-2' }} %</strong><i [style.color]="property_info?.color? property_info?.color : '#caa808'" class="fa fa-question-circle" aria-hidden="true"></i></p></div></div>
              </div>
            </div>

            <div class="card-footer">
              <div class="center">
                <button type="button" style="font-weight:normal; font-family:'Glacial Indifference'" *ngIf="!systemuser" class="btn btn-primary me-2" (click)="openLogin()">Login to Invest</button>
                <button type="button" style="font-weight:normal; font-family:'Glacial Indifference'" *ngIf="systemuser" class="btn btn-primary me-2" (click)="openInvest(invest)">Invest</button>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
    <!------------------SECOND TAB---------------------->
    <div class="tab-pane fade" id="nav-description" role="tabpanel" aria-labelledby="nav-profile-tab">
      <div class="main-main3">
        <div class="mt-5 p-3" style="font-weight:normal; font-family:'Glacial Indifference';font-size:20px;" [innerHTML]="property.entity.note"></div>
      </div>
    </div>
    <!------------------THIRD TAB---------------------->
    <div class="tab-pane fade" id="nav-document" role="tabpanel" aria-labelledby="nav-contact-tab">
      <div class="row main-main mt-2">
        <table class="table table-xs  table-borderless">
          <tbody>
            <tr *ngFor="let doc of property.documents">
              <td>
                <div class="row">
                  <div class="col-sm-10 mb-3 ">
                    <input style="text-align:left;" class="form-control" type="button" id="formFileDisabled" [value]="doc?.requirementObject?.name" placeholder="" disabled>
                  </div>
                  <div class="col-sm-2 mb-3 ">
                    <button class="btn primary" (click)="download_doc(doc)" [disabled]="!doc.attachments?.length"><img src="../../../assets/svgs/download.svg" alt="download" /><strong>Download</strong></button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!------------------FOURTH TAB---------------------->
    <div class="tab-pane fade" id="nav-floorplan" role="tabpanel" aria-labelledby="nav-floorplan-tab">
      <div class="row main-main mt-1">
        <img src="{{property_info?.floorPlans}}" class="img-fluid" alt="No Floor plan provided yet">
      </div>
    </div>

  </div>

  <!--<ng-template #gallery let-modal>

  </ng-template>-->
  <!--<ng-template #gallery let-modal>
    <div class="modal">
      <div align="right" style="float:right">
        <button type="button" class="btn-close btn-close-white" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="px-4 py-5 text-center header_image justify-content-end" [ngStyle]="{ 'background-image' : 'url(' + property.entity.photoUri + ')'}"></div>
      <div class="btn-row">
        <button class="btn-return" (click)="return(modal)" type="button">Return</button>
      </div>
    </div>
  </ng-template>-->
</div>


