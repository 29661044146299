import { ApiAccessService } from '../api-access.service';
import { userRegistration } from '../state/auth/auth.actions';
import { RegistrationData } from './../models/registration-data.model';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../app-service';
import { RegisterComponent } from '../dialogs/register/register.component';
import { SystemUser } from '../models/systemuser.model';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { getUser } from '../state/auth/auth.selectors';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  registerModal: NgbModalRef;
  show_display: boolean = true;
  systemuser: SystemUser;
  systemUser$ = this.store.select(getUser);
  constructor(private modalService: NgbModal, private store: Store<AppState>, private apiService: ApiAccessService, private appService: AppService) { }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.systemUser$.subscribe(user => { this.systemuser = user; });
    let subscription = this.appService.show_display().subscribe(item => this.show_display = item);
  }


  openRegister() {
    this.registerModal = this.modalService.open(RegisterComponent, { centered: true});
  }
}
