import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Address } from './models/address';
import { Bank } from './models/bank';
import { ContactNumber } from './models/contact-number';
import { Email } from './models/email';
import { Entity } from './models/Entity';
import { PayGateRequest } from './models/paygate-request';
import { PaymentMethod } from './models/payment-method';
import { Property } from './models/property.model';
import { RegistrationData } from './models/registration-data.model';
import { SignInData } from './models/sign-in-data.model';
import { SystemUser } from './models/systemuser.model';
import { UserInfo } from './models/user-info';
import { Transaction } from './models/transaction.model';
import { SystemCode, SystemCodeModel } from './models/system-code.model';
import { ResetPasswordResult, ResetPassword } from './models/reset-password.model';
import { ShareBought } from './models/share-bought.model';
import { ShareSelling } from './models/share-selling.model';
import { ShareDashBoard } from './models/share-dashboard.model';
import { AllProperties } from './models/all-properties.model';
import { SystemConfiguration } from './models/system-configuration.model';


@Injectable({
  providedIn: 'root'
})
export class ApiAccessService {
  constructor(private http: HttpClient, @Inject("BASE_URL") private base_url: string) { }

  registration(data: RegistrationData) {
    return this.http.post<SystemUser>(this.base_url + "auth/registration", data);
  }

  user_info()  {
    return this.http.get<SystemUser>(this.base_url + "auth/user-info");
  }

  sign_in(data: SignInData) {
    return this.http.post<SystemUser>(this.base_url + "auth/sign-in", data);
  }

  sign_out() {
    return this.http.post(this.base_url + "auth/sign-out", {});
  }

  get_all_properties() {
    return this.http.get<Property[]>(this.base_url + "sales/all-properties");
  }

  get_properties() {
    return this.http.get<AllProperties>(this.base_url + "sales/all-properties");
  }

  get_property(entity: number, market: boolean = false) {
    return this.http.get<UserInfo>(this.base_url + `entity/details?id=${entity}&Addresses=true&Documents=true&Attachments=true&` + (!market ? `ObjectsSimplified=true` : `ObjectsForMarket=true`));
  }

  createTransaction(instance: number, amount: number, method: PaymentMethod) {
    return this.http.post<PayGateRequest>(this.base_url + "entityproduct/with-object", { instance, amount, method });
  }

  get_user_information() {
    return this.http.get<UserInfo>(this.base_url + "entity/details?ForMe=true&Addresses=true&Emails=true&ContactNumbers=true&Banks=true");
  }

  save_address(address) {
    return this.http.post<Address>(this.base_url + "address/save", address);
  }

  save_email(email) {
    return this.http.post<Email>(this.base_url + "email/save", email);
  }

  save_contact_number(contact_number) {
    return this.http.post<ContactNumber>(this.base_url + "contactnumber/save", contact_number);
  }

  save_bank(bank) {
    return this.http.post<Bank>(this.base_url + "bank/save", bank);
  }

  del_address(id: number) {
    return this.http.delete(this.base_url + `address/${id}`);
  }

  del_email(id: number) {
    return this.http.delete(this.base_url + `email/${id}`);
  }

  del_contact_number(id: number) {
    return this.http.delete(this.base_url + `contactnumber/${id}`);
  }

  del_bank(id: number) {
    return this.http.delete(this.base_url + `bank/${id}`);
  }

  save_entity(entity) {
    return this.http.post<Entity>(this.base_url + "entity/update", entity);
  }

  getTransaction() {
    return this.http.get<Transaction>(this.base_url + "entityproduct/my-info");
  }

  getTransactions() {
    console.log("api");
    return this.http.get<Transaction[]>(this.base_url + "payment/my-transactions");
  }

  get_system_codes(lst: SystemCodeModel[]) {
    return this.http.post<Map<string, SystemCode[]>>(this.base_url + "system-codes/collection", lst);
  }

  get_suburbs(value: string) {
    return this.http.get<SystemCode[]>(this.base_url + "system-codes/places?Value=" + value);
  }

  request_reset_password(value: string) {
    return this.http.post<ResetPasswordResult>(this.base_url + "auth/reset-password/" + value, {});
  }

  reset_password(value: ResetPassword) {
    return this.http.post(this.base_url + "auth/reset-password", value);
  }

  get_dashboard() {
    return this.http.get<ShareDashBoard>(this.base_url + "share/get-dashboard/for-me");
  }

  buy_share(share: ShareBought) {
    return this.http.post(this.base_url + "share/buy", share);
  }

  sell_share(share: ShareSelling) {
    return this.http.post(this.base_url + "share/sell", share);
  }

  revert_selling_share(share: ShareSelling) {
    return this.http.post(this.base_url + "share/sell/revert", share);
  }

  get_configuration(name: string) {
    return this.http.get<SystemConfiguration[]>(this.base_url + `system-config?Name=${name}`);
  }

  get_file(url: string) {
    return this.http.get(this.base_url + url, { responseType: 'blob' as 'json', observe: 'response' }).subscribe((response: HttpResponse<Blob>) => {
      var fileNamePattern = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/;
      let dataType = response.headers.get('content-type');
      let binaryData = [];
      binaryData.push(response.body);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      downloadLink.setAttribute('download', fileNamePattern.exec(response.headers.get('content-disposition'))[1]);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    });
  }

}
