import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppService } from '../../app-service';
import { SignInData } from '../../models/sign-in-data.model';
import { userSignIn } from '../../state/auth/auth.actions';
import { AuthState } from '../../state/auth/auth.reducer';
import { RegisterComponent } from '../register/register.component';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  @Output()
  close_model = new EventEmitter<string>();

  loginForm: FormGroup;
  registerModal: NgbModalRef;
  resetpasswordModal: NgbModalRef;
  is_register_open: boolean = false;

  constructor(private store: Store<AuthState>, private modalService: NgbModal, public activeModal: NgbActiveModal, private appService: AppService) { }

  ngOnInit() {
    this.appService.set_display(false);
    this.loginForm = new FormGroup({
      username: new FormControl(),
      password: new FormControl()
    });

    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    if (!this.is_register_open) {
      this.appService.set_display(true);
    }
  }

  openRegister() {
    this.is_register_open = true;
    this.registerModal = this.modalService.open(RegisterComponent, { centered: true});
    this.close('open register');
  }

  openResetPassword() {
    this.resetpasswordModal = this.modalService.open(ResetPasswordComponent, { centered: true});
    this.close('open reset password');
  }

  submit_loginform(data) {
    let result: SignInData = { username: this.loginForm.value.username, deviceKey: "dev.propmagnate.temodigital.com", password: this.loginForm.value.password };
    this.store.dispatch(userSignIn(result));
    this.close("Login Complete");
  }  

  close(message: string) {
    this.close_model.emit(message);
    this.activeModal.close(message);
  }
}
