<div class="page-body">
  <div class="container">

    <img src="https://temodiag.blob.core.windows.net/property-magnate/images/assets/Landing-Page-Mock.png" class="img-fluid" alt="Responsive image">

    <div [hidden]="!show_display" class="page-content row">
      <div class="content col-sm-4" *ngIf="!systemuser">

        <p class="pttt1">
          make the smart
        </p>
        <p class="pttt2">
          <b>investment</b>
        </p>
        <p class="ptt3">
          choices with us
        </p>
        <div class="center" >
          <button type="button" class="btn-reg btn-primary me-2" (click)="openRegister()">Register Today</button>
        </div>
      </div>
      <div class="content loggedin col-sm-4" *ngIf="systemuser" >

        <p class="pttt1">
          make the smart
        </p>
        <p class="pttt2">
          <b>investment</b>
        </p>
        <p class="ptt3">
          choices with us
        </p>
       
      </div>
    </div>
  </div>
</div>
