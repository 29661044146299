import { createReducer, on } from "@ngrx/store";
import { saveObjectValue, saveObjectValueFailed, saveObjectValueSuccessful } from "./object-value.actions";

export interface ObjectState {
  object: any
}

export const initialState: ObjectState = {
  object: null
};

export const objectReducer = createReducer(
  initialState,
  on(saveObjectValue, (_) => initialState),
  on(saveObjectValueSuccessful, (state) => ({ ...state })),
  on(saveObjectValueFailed, (state, _) => initialState)
);
