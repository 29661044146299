import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { camelCase } from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Alert } from '../models/alert.model';
import { AppState } from '../state/app.state';
import { alertRecieved } from '../state/system/system.actions';


@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  msg: string;
  constructor(private state: Store<AppState>) { }
    
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.msg = "";
    request = request.clone({ withCredentials: true });

    return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.body.Status == 200) {
              if (event.body.Alert) {
                this.msg = event.body.Alert.Message;
                this.state.dispatch(alertRecieved(new Alert(event.body.Alert.Message, event.body.Alert.Type)));
              }
              event = event.clone({ body: camelizeKeys(event.body.Data) });
            } else if (event.body.Status == 204) {//no content

            } else if (event.body.Status > 399 && event.body.Status < 600) {
              if (event.body.Alert) {
                this.msg = event.body.Alert.Message;
                this.state.dispatch(alertRecieved(new Alert(event.body.Alert.Message, event.body.Alert.Type)));
              } else {
                this.msg = event.body.Message;
                this.state.dispatch(alertRecieved(new Alert(event.body.Message, 2)));
              }
              throw new HttpErrorResponse({
                error: this.msg,
                headers: event.headers,
                status: event.body.Status,
                url: event.url
              });
            } else if (event.status == 200) {

            } else {
              throw new HttpErrorResponse({
                error: event.body.Message,
                headers: event.headers,
                status: event.body.Status,
                url: event.url
              });
            }
            return event;
          }
        })
        ,
        catchError((error: HttpErrorResponse) => {
          return throwError(error.message);
        })
      );
  }
}

const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};
