import { AfterViewInit, Component, ElementRef, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Toast } from "bootstrap";
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { Alert } from '../../models/alert.model';
import { alertRecieved } from '../../state/system/system.actions';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css'],
  host: { 'class': 'toast-container position-absolute top-0 end-0 p-3' }
})
export class AlertsComponent implements AfterViewInit, OnDestroy {

  @ViewChildren("toastList") toastList: QueryList<ElementRef>;

  constructor(private actions$: Actions) {
  }
  toasts: (Alert & { id: string })[] = [];

  destroyed$ = new Subject<boolean>();

  ngAfterViewInit() {
    this.actions$.pipe(
      ofType(alertRecieved),
      takeUntil(this.destroyed$)
    ).subscribe(x => {
      this.toasts.push({ ...x, id: uuidv4() });
    });

    this.toastList.changes.subscribe(() => {
      this.toastList.toArray().forEach((v) => {
        const element = (<HTMLElement>v.nativeElement);
        if (element.id && !element.classList.contains("show")) {
          Toast.getOrCreateInstance(element).show();
          const comp = this;
          element.addEventListener('hidden.bs.toast', function (e: CustomEvent) {
            const i = comp.toasts.findIndex(x => x.id === (<HTMLElement>e.target).id);
            comp.toasts.splice(i, 1);
          });
        }
      });
    });
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

}
