import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ApiAccessService } from "../../api-access.service";
import { getProperties, getPropertiesFailed, getPropertiesSuccessful } from "./properties.actions";

import { of, from } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { Router } from "@angular/router";


@Injectable()
export class PropEffects {
  constructor(private router: Router, private actions$: Actions, private apiService: ApiAccessService) { }
  signOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProperties),
      switchMap(() => from(this.apiService.get_all_properties().pipe(
        map((props) => getPropertiesSuccessful({ properties: props })),
        catchError((error) => of(getPropertiesFailed({ error })))))
      )
    )
  );
}
