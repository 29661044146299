<div class="result-modal">
  <div *ngIf="options.icon_string">
    <i [class]="options.icon_string" [style.color]="options.icon_color? options.icon_color : ''" style="font-size: 2.5em "></i>
  </div>
  <div *ngIf="options.icon">
    <img src="{{options.icon}}" alt="Icon" [style.color]="options.icon_color? options.icon_color : ''" />
  </div>
  <div *ngIf="options.title">
    <div style="font-size:25px;color:white;">{{options.title}}</div>
  </div>
  <div *ngIf="options.message">
    <div style="font-size:15px;color:white;">{{options.message}}</div>
  </div>
  <div>
    <div class="form-actions">
      <button class="button" type="button" (click)="close('ok')">
        {{options.button_message?? 'Close' }}
      </button>
    </div>
  </div>
</div>
